import en from './en-us.json'
import he from './he-il.json'
import de from './de.json'
import ar from './ar.json'
import fr from './fr.json'
import es from './es.json'
import it from './it.json'
import ru from './ru.json'

//new lang add here #1/4
export default {
  he,
  en,
  ar,
  fr,
  es,
  it,
  ru,
  de
}
