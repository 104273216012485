import { Parse } from 'src/boot/parse'

export function set ({ commit }, category) {
  commit('CATEGORIES_SET', category)
}

export async function getById ({ commit }, categoryId) {
  commit('CATEGORIES_LOADING', true)
  const category = await new Parse.Query('Category')
    .get(categoryId)

  commit('CATEGORIES_SET', category)
  commit('CATEGORIES_LOADING', false)

  return category
}

export async function get ({ commit, state }, business) {
  commit('CATEGORIES_LOADING', true)
  return await new Parse.Query('Category')
    .equalTo('business', business)
    .ascending('title')
    .find()
    .then(categories => {
      commit('CATEGORIES_STORE', categories)
      commit('CATEGORIES_LOADING', false)
    })
    .catch(error => {
      console.log('#### Categories error:', error)
    })
}

export async function countItemsForCategories({ rootState, commit, getters, rootGetters }, categoriesArray) {
  const promises = []

  const isTA = rootState.orders.mode !== 'eat_in'

  const business = rootGetters['business/current']

  const menus = rootGetters['business/activeMenus']

  if (business) {
    categoriesArray.forEach(category => {
      const promise = new Promise((resolve, reject) => {
        const query = new Parse.Query('RestaurantItem')

        query.containedIn('categories', [category.id])
        query.containedIn('menus', menus)
        query.equalTo('business', business)
        query.notEqualTo('archive', true)

        if (isTA) {
          query.equalTo('take_away', true)
        }

        query.count().then(result => {
          commit('SET_COUNTED', {
            categoryId: category.id,
            counts: result
          })
          resolve()
        })
      })

      promises.push(promise)
    });

    // Count Specials
    const specialCountPromise = new Promise((resolve, reject) => {
      const query = new Parse.Query('RestaurantItem')
      const now = new Date()

      query.equalTo('business', business)
        .notEqualTo('archive', true)
        .exists("price_special_expiration", now)
        .greaterThanOrEqualTo("price_special_expiration", now)
        .containedIn("menus", rootState.business.menus)
        .exists("price_special", now)

      if (isTA) {
        query.equalTo('take_away', true)
      }

      query.count().then(result => {
        commit('SET_COUNTED', {
          categoryId: 'specials',
          counts: result
        })
        resolve()
      })
    })

    promises.push(specialCountPromise)

    await Promise.all(promises)
  }
}
