export const one = (state) => (id) => {
  return state.all[id]
}

// export const takeAwayAll = (state) => {
//   return Object.values(state.all).filter(item => !!item.get('take_away'))
// }

export const category = (state) => (category) => {
  return state.categories[category.id]
}

export function warnings (state) {
  return state.warnings
}

export function loading (state) {
  return state.loading
}

export function autocomplete (state) {
  return state.autocomplete
}

export const categoryItem = (state) => (categoryId, itemId) => {
  for (const i in state.categories[categoryId]) {
    if (state.categories[categoryId][i].id === itemId) {
      return state.categories[categoryId][i]
    }
  }
}

export const itemWarnings = (state) => (warnings) => {
  const results = []
  for (const i in warnings) {
    results.push(state.warnings[warnings[i]])
  }
  return results
}

export const labels = (state) => (warnings) => {
  const results = []
  for (const id of warnings) {
    if (state.warnings[id] && state.warnings[id].get('type') === 1) {
      results.push(state.warnings[id])
    }
  }
  return results
}

export const allergens = (state) => (warnings) => {
  const results = []
  for (const i in warnings) {
    if (state.warnings[warnings[i]] && state.warnings[warnings[i]].attributes && state.warnings[warnings[i]].get('type') === 0) {
      results.push(state.warnings[warnings[i]])
    }
  }
  return results
}

export function warningsLoading (state) {
  return state.warningsLoading
}

export const extra = (state) => (extraId) => {
  return (state.extras[extraId] && state.extras[extraId].attributes) ? state.extras[extraId] : false
}

export const extrasByItem = (state) => (itemId) => {
  return state.extrasByItem[itemId] || []
}

export function extrasLoading (state) {
  return state.extrasLoading
}

export function specials (state) {
  return state.specials
}

export const getQuantityOfItems = (state) => (id) => {
  let quantity = 0
  for (const i in state.all) {
    if (state.all[i].get('categories').includes(id)) {
      quantity++
    }
  }
  return quantity
}
