import { LocalStorage } from 'quasar'
import { Parse } from 'src/boot/parse'

export function parsePrototype ({ dispatch, state, rootState }, item = false) {
  const itemPrototype = (item === false) ? state.currentItem.toJSON() : item

  if (item === false) {
    delete itemPrototype.objectId
    delete itemPrototype._localId
  }

  itemPrototype.client = {
    __type: 'Pointer',
    className: '_User',
    objectId: rootState.auth.user.id
  }

  if (rootState.orders.current && rootState.orders.current.id) {
    itemPrototype.restaurant_order_summary = {
      __type: 'Pointer',
      className: 'RestaurantOrderSummary',
      objectId: rootState.orders.current.id
    }
  }

  const extras = itemPrototype.extras
  itemPrototype.extras = []

  if (extras && extras.length) {
    for (const i in extras) {
      if (extras[i].__type && extras[i].__type === 'Pointer') {
        itemPrototype.extras.push(extras[i])
      } else {
        itemPrototype.extras.push({
          __type: 'Pointer',
          className: 'RestaurantItemExtra',
          objectId: extras[i].objectId ? extras[i].objectId : extras[i].id
        })
      }
    }
  }
  itemPrototype.business = rootState.business.business
  return itemPrototype
}

export async function newItem ({ commit, rootState }, itemData) {
  commit('CART_RESET_CURRENT_ITEM')
  commit('CART_SET_CURRENT_ITEM', await new Parse.Object('RestaurantOrder', {
    ...itemData,
    business: rootState.business.business
  }))
}

export async function editItem ({ commit, state }, itemIndex) {
  commit('CART_RESET_EXTRAS')
  commit('CART_RESET_CURRENT_ITEM')
  commit('CART_SET_CURRENT_ITEM', state.items[itemIndex])
}

export async function updateItem ({ commit, dispatch, state }, { itemIndex, orderItemData }) {
  for (const i in orderItemData) {
    state.currentItem.set(i, orderItemData[i])
  }
  commit('CART_UPDATE_CURRENT_ITEM', itemIndex)
  commit('CART_RESET_EXTRAS')
}

export async function toggleExtra ({ commit, rootState }, { extra, status }) {
  if (!extra.id) {
    extra = rootState.items.extras[extra.objectId]
  }

  if (status === undefined) {
    commit('ITEMS_CART_TOGGLE_ITEM_EXTRA', extra)
  } else {
    commit('ITEMS_CART_SET_ITEM_EXTRA', { extra: extra, status: status })
  }
}

export async function saveItem ({ commit, dispatch, state, rootState }, { amount, orderItemData }) {
  commit('CART_LOADING', true)

  const itemPrototype = Object.assign(await dispatch('parsePrototype'), orderItemData)
  const promises = []

  const items = []
  for (let i = 0; i < amount; i++) {
    items[i] = JSON.parse(JSON.stringify(itemPrototype))
    items[i].restaurant_item = state.currentItem.get('restaurant_item')
    items[i].business = rootState.business.business
    promises.push(new Parse.Object('RestaurantOrder', items[i]))
  }

  Promise.all(promises).then((savedItems) => {
    commit('CART_ADD_ITEMS', savedItems)
    commit('CART_RESET_EXTRAS')
    commit('CART_RESET_CURRENT_ITEM')
    commit('CART_LOADING', false)
  })
}

export async function deleteCurrentItem ({ commit, state }) {
  state.currentItem.destroy()
}

export async function deleteItem ({ commit, state }, itemIndex) {
  return await state.items[itemIndex].destroy().then(() => {
    commit('CART_DELETE_ITEM', itemIndex)
  })
}

export async function removeItems ({ commit, dispatch, state }) {
  while (state.items.length) {
    const items = state.items
    for (const i in items) {
      commit('CART_DELETE_ITEM', i)
    }
  }
}

export function reset ({ commit }) {
  commit('CART_RESET')
}

export function resetCurrentItem ({ commit }) {
  commit('CART_RESET_CURRENT_ITEM')
  commit('CART_RESET_EXTRAS')
}

export async function refresh ({ commit, dispatch, rootState }) {
  commit('CART_LOADING', true)

  let items = LocalStorage.has('cart.items') ? LocalStorage.getItem('cart.items') : false
  if (items === null) {
    items = []
  }

  if (items && items.length) {
    for (const i in items) {
      items[i] = await new Parse.Object('RestaurantOrder', await dispatch('parsePrototype', items[i]))
    }
    commit('CART_SET_ITEMS', { items: items, order: rootState.orders.current })
  }

  commit('CART_READY')
  commit('CART_LOADING', false)
}
