export const item = (state) => (index) => {
  return state.items[index]
}

export function ready (state) {
  return state.ready
}

export function currentItem (state) {
  return state.currentItem
}

export function loading (state) {
  return state.loading
}

export function items (state) {
  return state.items
}

export function enabledExtras (state) {
  return state.enabledExtras
}

export const itemsCountById = (state) => (itemId) => {
  return state.itemsCountById[itemId]
}
