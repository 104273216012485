export function all (state) {
  return state.all
}

export function loading (state) {
  return state.loading
}

export function saving (state) {
  return state.saving
}

export function deleting (state) {
  return state.deleting
}
