export default {
  data () {
    return {
      mobileBreakpoint: process.env.MOBILE_BREAKPOINT
    }
  },

  computed: {
    mobile () { return (this.$q.screen.width < this.mobileBreakpoint) },
    desktop () { return (this.$q.screen.width >= this.mobileBreakpoint) },
  },

  methods: {
    mobileAttr (mobileAttr) {
      return (this.$q.screen.width < this.mobileBreakpoint) ? mobileAttr : ''
    },

    desktopAttr (desktopAttr) {
      return (this.$q.screen.width < this.mobileBreakpoint) ? '' : desktopAttr
    },

    responsiveAttr (mobileAttr, desktopAttr) {
      return (this.$q.screen.width < this.mobileBreakpoint) ? mobileAttr : desktopAttr
    }
  }
}
