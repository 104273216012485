import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      logged: 'auth/logged',
      loggedUser: 'auth/user'
    })
  }
}
