import { Parse } from 'src/boot/parse'
import { getTimeOfTimezone } from 'src/utils/index'

export async function set ({ commit, dispatch, state }, business) {
  dispatch('unset')
  commit('BUSINESS_LOADING', true)

  // clear data of previous business
  commit('categories/CATEGORIES_STORE', [], { root: true })
  commit('items/ITEMS_DELETE_ALL', {}, { root: true })
  commit('cart/CART_RESET', {}, { root: true })

  if (state.business.id !== business.id) {
    await Promise.all([
      dispatch('getMenus', business),
      dispatch('getHours', business)
    ])

    const currency = business.get('currency')
    commit('BUSINESS_SET_CURRENCY', currency)
    commit('BUSINESS_SET', business)
    commit('layout/LAYOUT_SET_BUSINESS_POPUP', false, { root: true })

    dispatch('cart/reset', null, { root: true })
  }
  commit('BUSINESS_LOADING', false)
}

// TODO: fix summer time issue. Find time by city or location of business
// for current business time
export async function setClock ({ commit, state, getters }) {
  const business = getters.current

  if (!business) return

  const timezone = business.get('time_zone')

  if (!state.clock) {
    const getTime = () => {
      return getTimeOfTimezone(timezone)
    }

    commit('BUSINESS_SET_CLOCK', getTime())
    setInterval(() => {
      commit('BUSINESS_SET_CLOCK', getTime())
    }, 10000)
  }
}

export function unset ({ commit }) {
  commit('BUSINESS_UNSET')
}

export async function browse ({ commit, dispatch, rootGetters }, location = false) {
  commit('BUSINESS_LOADING', true)
  const query = new Parse.Query('Business')
  const userLocation = rootGetters['auth/location']

  // Filter hidden businesses
  query.equalTo('hide_from_client_search', false)
  if (location) {
    const GeoPoint = new Parse.GeoPoint(location.latitude, location.longitude);
    query.near('location', GeoPoint)
  } else if (userLocation) {
    query.near('location', userLocation)
  }

  return query
    .find()
    .then(businesses => {
      commit('BUSINESS_STORE_BUSINESSES', businesses)
    })
    .catch(error => {
      console.log('#### Business error:', error)
    })
    .finally(() => {
      commit('BUSINESS_LOADING', false)
    })
}

export async function getMenus ({ commit }, business) {
  return await new Parse.Query('RestaurantMenu')
    .equalTo('business', business)
    .find()
    .then(menus => {
      commit('BUSINESS_SET_MENUS', menus)
    })
    .catch(error => {
      console.log('#### Business menus error:', error)
    })
}

export async function getHours ({ commit }, business) {
  return await new Parse.Query('OpeningHours')
    .equalTo('business', business)
    .find()
    .then(hours => {
      commit('BUSINESS_SET_HOURS', { business, hours })
    })
    .catch(error => {
      console.log('#### Business hours error:', error)
    })
}

export async function refresh ({ commit, dispatch, state }) {
  if (state.businessId) {
    // try {
      const business = await new Parse.Query('Business')
        .include('currency')
        .get(state.businessId)

      if (business) {
        const currency = business.get('currency')
        commit('BUSINESS_SET_CURRENCY', currency)

        await Promise.all([
          dispatch('getMenus', business),
          dispatch('getHours', business),
        ])
        if (state.business && business.id !== state.business.id) {
          commit('BUSINESS_CLEAR_LOCALSTORAGE')
        }
        commit('BUSINESS_SET', business)
        return business
      } else {
        console.log('#### Refresh Business: Business not found')
        dispatch('layout/setBusinessPopup', true, { root: true })
      }
    // } catch (err) {
    //   console.log('#### Refresh Business: ', err)
    //   dispatch('layout/setBusinessPopup', true, { root: true })
    // }
  } else {
    dispatch('layout/setBusinessPopup', true, { root: true })
  }
  return false
}

export function getAndSet ({ commit, dispatch }, businessId) {
  dispatch('unset')

  return new Promise(resolve => {
    new Parse.Query('Business').include('currency').get(businessId)
      .then(business => {
        const currency = business.get('currency')
        commit('BUSINESS_SET_CURRENCY', currency)
        dispatch('set', business)
        resolve({
          success: true,
          business
        })
      }).catch(error => {
        resolve({
          success: false,
          error
        })
      })
  })
}

export async function getCurrency ({ dispatch, state, commit }, currencyId) {
  return new Parse.Query('Currency').get(currencyId)
    .then(currency => {
      commit('BUSINESS_SET_CURRENCY', currency)
    })
}
