export default [
  {
    path: 'orders/checkout',
    name: 'order.checkout',
    components: {
      default: () => import('src/pages/cart/Checkout.vue'),
      toolbar: () => import('src/components/checkout/Toolbar.vue')
    },
    meta: {
      sideMenuShadow: true,
      allowNoOrder: true
    }
  },
  {
    path: 'orders/summary',
    name: 'order.summary',
    components: {
      default: () => import('src/pages/orders/Summary.vue'),
      toolbar: () => import('src/components/summary/Toolbar.vue')
    },
    meta: {
      auth: true,
      sideMenuShadow: true,
      retainOrder: true
    }
  },
  {
    path: 'orders/tracker',
    components: {
      default: () => import('src/pages/orders/Tracker.vue')
    },
    meta: {
      auth: true,
      sideMenuShadow: false,
      retainOrder: true,
      pageBg: 'white',
      hideScrollbar: true
    },
    children: [
      {
        path: '',
        name: 'order.tracker.order',
        components: {
          tracker: () => import('src/components/tracker/Order.vue')
        },
        meta: {
          auth: true,
          pageBg: 'white',
          hideScrollbar: true
        }
      },
      {
        path: 'status',
        name: 'order.tracker.status',
        components: {
          tracker: () => import('src/components/tracker/Status.vue')
        },
        meta: {
          auth: true,
          pageBg: 'white',
          hideScrollbar: true
        }
      },
      {
        path: 'payment',
        name: 'order.tracker.payment',
        components: {
          tracker: () => import('src/components/tracker/Pay.vue')
        },
        meta: {
          auth: true,
          pageBg: 'white',
          hideScrollbar: true
        }
      }
    ]
  },
  {
    path: 'orders/referral/:id',
    name: 'order.referral',
    component: () => import('src/pages/orders/Referral.vue'),
    meta: {
      sideMenuShadow: true
    }
  }
]
