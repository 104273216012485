import categoriesRoutes from './routes/categories'
import staticRoutes from './routes/static'
import orderRoutes from './routes/order'
import userRoutes from './routes/user'

const routes = [
  {
    path: '/',
    component: () => import('src/layouts/Main.vue'),
    children: [
      ...staticRoutes,
      ...categoriesRoutes,
      ...orderRoutes,
      ...userRoutes,
    ]
  },
  {
    path: '/external-orders/',
    name: 'external.orders',
    component: () => import('src/pages/orders/ExternalOrders.vue'),
    meta: {
      allowEmptyOrder: true,
    },
  },
  {
    path: '/external-orders/order-finished',
    name: 'external.orders.order.finished',
    component: () => import('src/pages/orders/OrderFinished.vue')
  },
  {
    // direct link to business
    // Sample QR code for Gypsy: https://client.dreamdiner.io/business/direct/iKOEFd7U6v
    // Sample QR code for Aminadav: https://client.dreamdiner.io/business/direct/qXg4oO22S1
    path: '/business/direct/:businessId',
    name: 'business.direct',
    component: () => import('src/pages/business/Set.vue'),
    meta: {
      sideMenuShadow: false,
      allowEmptyOrder: true,
      redirectActiveOrder: true
    }
  },

  // 404 throws back to base path
  {
    path: '*',
    redirect: '/'
  }
]

export default routes
