import { LocalStorage } from 'quasar'

export default function () {
  return {
    sideMenuShadow: true,
    routerPopup: false,
    tablesPopup: false,
    paymentsPopup: false,
    loginPopup: false,
    businessPopup: false,
    fontZoom: LocalStorage.has('layout.zoom') ? LocalStorage.getItem('layout.zoom') : 1
  }
}
