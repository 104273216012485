import { Parse } from 'src/boot/parse'

export async function save ({ commit }, { ratingData, restaurantItemRatings }) {
  commit('RATINGS_LOADING', true)
  let helperObj = {}

  for (const i in restaurantItemRatings) {
    helperObj = new Parse.Object('RestaurantItemRating', restaurantItemRatings[i])
    helperObj.unset('tempScore')
    helperObj.save()

    ratingData.item_ratings.push(helperObj)
    ratingData.ratings.push(helperObj.get('rating'))
  }
  await new Parse.Object('Rating', ratingData).save()
  commit('RATINGS_LOADING', false)
}

export async function find({ commit }, orderId) {
  commit('RATINGS_LOADING', true)
  const ratingObject = Parse.Object.extend("Rating");
  const query = new Parse.Query(ratingObject);
  
  query.equalTo('restaurant_order_summary', {
    __type: 'Pointer',
    className: 'RestaurantOrderSummary',
    objectId: orderId
  })
  
  const ratings = await query.find()
  
  commit('RATINGS_SET_CURRENT', ratings[0] ? ratings[0] : false)
  commit('RATINGS_LOADING', false)

}

// export async function update({ state, commit}) {
//   commit('RATINGS_LOADING', true)
  
//   commit('RATINGS_LOADING', false)
// }
