import { LocalStorage } from 'quasar'

export function orderId (state) {
  return state.orderId
}

export function items (state) {
  if (state.current && state.current.attributes && state.current.get('item_orders')) {
    return state.current.get('item_orders')
  }
  return []
}

export function tableOrdersLoading (state) {
  return state.tableOrdersLoading
}

export function hasWaiterCall (state) {
  return state.hasWaiterCall
}

export function data (state) {
  return state.data
}

export function current (state) {
  return state.current
}

export function loading (state) {
  return state.loading
}

export const paid = (state) => (order = false) => {
  if (order === false) {
    order = state.current
  }

  if (!order || !order.attributes || !Object.keys(order.attributes).length) {
    return false
  }

  return !!(
    order.get('paid') ||
    order.get('paid_by_kitchen') ||
    order.get('paid_by_waiter') ||
    order.get('paid_by_admin') ||
    order.get('closed_by_admin')
  )
}

export const delivered = (state) => {
  const order = state.current
  return (order && order.get('item_orders').length === order.get('item_orders_delivered').length)
}

export const finished = (state) => (order = false) => {
  if (order === false) {
    order = state.current
  }

  return (
    order &&
    order.get('item_orders').length === order.get('item_orders_delivered').length &&
    (
      !!order.get('paid') ||
      !!order.get('paid_by_kitchen') ||
      !!order.get('paid_by_waiter') ||
      !!order.get('paid_by_admin') ||
      !!order.get('closed_by_admin')
    )
  )
}

export function mode (state) {
  return state.mode
}

export function payments (state) {
  return state.payments
}

export function socketUpdateFlag (state) {
  return state.socketUpdateFlag
}

export function all (state) {
  return state.all
}

export const generalItems = (state) => (order = false) => {
  if (order === false) {
    order = state.current
  }

  if (order) {
    if (order.get('general_items') && order.get('general_items').length) {
      return order.get('general_items')
    }
  }
  return []
}

export const one = (state) => (orderId) => {
  for (const i in state.all) {
    if (state.all[i].id === orderId) {
      return state.all[i]
    }
  }
  return false
}

export const table = (state) => (tableId, exclude = false) => {
  const result = []
  for (const i in state.all) {
    if (
      !state.all[i].get('closed_by_admin') &&
      !state.all[i].get('paid') &&
      state.all[i].get('table') &&
      state.all[i].get('table').id === tableId &&
      state.all[i].id !== exclude
    ) {
      result.push(state.all[i])
    }
  }
  return result
}

/**
 * skipRefresh - we need to skip refresh order in case of new staff order
 * because new order will be created upon finishing ordering
 * @param {*} state
 * @returns Boolean
 */
export const skipRefresh = (state) => {
  const isStaff = state.role !== 'guest'
  const hasStaffOrder = !!LocalStorage.getItem('order.staffOrder')

  if (isStaff && !hasStaffOrder) {
    return true
  }

  return false
}

export const activeOrders = (state, getters, rootState) => {
  return state.all.filter(order => {
    return order.get('client') && order.get('client').id === rootState.auth.user.id && (!getters.paid(order) || !(order.get('item_orders').length === order.get('item_orders_delivered').length)) 
  })
}

export const businessActiveOrders = (state, getters) => (businessId) => {
  if(!getters.activeOrders.length) return []

  return getters.activeOrders.filter(order => order.get('business').id === businessId )
}
