export function setSideMenuShadow ({ commit }, status) {
  commit('LAYOUT_SET_SIDE_MENU_SHADOW', status)
}

export function setRouterPopup ({ commit }, status) {
  commit('LAYOUT_SET_ROUTER_POPUP', status)
}

export function setTablesPopup ({ commit }, status) {
  commit('LAYOUT_SET_TABLES_POPUP', status)
}

export function setPaymentsPopup ({ commit }, status) {
  commit('LAYOUT_SET_PAYMENTS_POPUP', status)
}

export function setLoginPopup ({ commit }, status) {
  commit('LAYOUT_SET_LOGIN_POPUP', status)
}

export function setBusinessPopup ({ commit }, status) {
  commit('LAYOUT_SET_BUSINESS_POPUP', status)
}

export function closeRedundantPopups ({ commit }, status) {
  commit('LAYOUT_SET_TABLES_POPUP', false)
  commit('LAYOUT_SET_PAYMENTS_POPUP', false)
  commit('LAYOUT_SET_LOGIN_POPUP', false)
  commit('LAYOUT_SET_BUSINESS_POPUP', false)
}
