import { Parse } from 'src/boot/parse'
import { Notify, Platform, LocalStorage } from 'quasar'
import { App } from '@capacitor/app'
import { i18n } from 'src/boot/i18n'

export function prepareOrderItems ({ commit, dispatch }, { order, items }) {
  const prevItems = order.get('item_orders')
  const ids = prevItems.map(item => item.get('internal_id'))
  const maxId = Math.max(...ids)

  let extras = []
  for (const i in items) {
    if (items[i].get('extras') && items[i].get('extras').length) {
      extras = items[i].get('extras')
      for (const j in extras) {
        extras[j] = {
          __type: 'Pointer',
          className: 'RestaurantItemExtra',
          objectId: (extras[j].id) ? extras[j].id : extras[j].objectId
        }
      }
      items[i].set('extras', extras)
    }

    if (!items[i].attributes) {
      items[i] = new Parse.Object('RestaurantOrder', items[i])
    }

    items[i].set('restaurant_order_summary', order)
    items[i].set('refund_notified', false)
    items[i].set('internal_id', parseInt(i) + 1 + maxId)

    if (order.attributes) {
      if (!items[i].get('order_waiter') && order.get('waiter')) {
        items[i].set('order_waiter', order.get('waiter'))
      }
      if (order.get('table')) {
        items[i].set('table', order.get('table'))
      }

      for (const j in order.get('item_orders')) {
        if (items[i].id === order.get('item_orders')[j].id) {
          items.splice(i, 1)
          break
        }
      }
    }
  }

  return items
}

export async function checkWaiterCalled ({ commit, state, dispatch }, order = false) {
  if (!order) {
    order = state.current
  }

  if (!state.waiterCallId) {
    return false
  }

  return await new Parse.Query('UserWaiterRelation')
    .get(state.waiterCallId)
    .then(async userWaiterRelations => {
      commit('ORDERS_SET_WAITER_CALL', userWaiterRelations)

      await dispatch('subscribeToWaiterRelation', userWaiterRelations)
    })
}

export async function callWaiter ({ commit, dispatch, state }, { pay = false, childOrders = [], call = false }) {
  if (state.waiterCall) {
    state.waiterCall.set('pay_waiter', pay)
    state.waiterCall.set('call_waiter', call)

    await state.waiterCall.save()
    // await state.waiterCall.destroy()
  } else {
    let waiterCall

    if (state.waiterCallId) {
      const query = new Parse.Query('UserWaiterRelation')
      waiterCall = await query.get(state.waiterCallId)
      console.log('waiterCall await = ', waiterCall, state.waiterCallId)

      waiterCall.set('pay_waiter', pay)
      waiterCall.set('call_waiter', call)
    } else {
      waiterCall = new Parse.Object('UserWaiterRelation', {
        business: state.current.get('business'),
        waiter: state.current.get('waiter'),
        call_waiter: call,
        client: Parse.User.current(),
        table: state.current.get('table'),
        pay_waiter: pay, // its call for cash
        kitchen_call: false,
        child_orders: childOrders
      })

      if (state.current) {
        waiterCall.set('restaurant_order_summary', state.current)
      }
    }

    await waiterCall.save()
    // console.log('waiterCall', waiterCall)
    commit('ORDERS_SET_WAITER_CALL', waiterCall)

    await dispatch('subscribeToWaiterRelation', waiterCall)
  }
}

export async function subscribeToWaiterRelation ({ state, commit }, call) {
  const subscription = await new Parse.Query('UserWaiterRelation')
    .equalTo('objectId', call.id)
    .subscribe()

  subscription.on('error', e => {
    console.log('#### [Call to waiter error]: ', e)
  })
  subscription.on('update', (userWaiterRelation) => {
    // console.log('on update', userWaiterRelation)
    commit('ORDERS_SET_WAITER_CALL', userWaiterRelation)
  })
  subscription.on('delete', (userWaiterRelation) => {
    commit('ORDERS_SET_WAITER_CALL', false)
  })
}

export async function doneRating ({ commit, state }, order = false) {
  if(order) {
    order.set('rated', true)
    await order.save()
  } else {
    state.current.set('rated', true)
    await state.current.save()
  }
}

export function checkOrderFinished ({ commit, dispatch, state }, order = false) {
  if (order === false) {
    order = state.current
  }
  if (
    state.orderId === order.id &&
    (
      order.get('closed_by_admin') ||
      (
        order.get('paid') &&
        order.get('item_orders').length === order.get('item_orders_delivered').length
        && order.get('rated')
      )
    )
  ) {
    commit('ORDERS_MODE_SET', false)
    dispatch('destroy', false)
    return true
  }
  return false
}

export function trackAppState ({ dispatch }) {
  // For tests only!
  // Notify.create({
  //   type: 'positive',
  //   message: 'Start App Tracking!'
  // })
  if (Platform.is.capacitor) {
    App.addListener('appStateChange', async ({ isActive }) => {
      if (isActive) {
        Notify.create({
          type: 'warning',
          message: i18n.t('tracker.tracker_refreshing')
        })
        dispatch('startSubscription')
      }
    });
  }
}

export async function startSubscription ({ commit, state, dispatch }) {
  let subscription = {}
  subscription = await new Parse.Query('RestaurantOrderSummary')
    .equalTo('objectId', state.current.id)
    .subscribe()

  subscription.on('error', e => {
    console.log('#### Socket Error', e)
  })
  subscription.on('update', async (order) => {
    commit('ORDERS_LOADING', true)

    if (!order.isDataAvailable()) {
      await order.fetch();
    }
    // refresh order items for current data
    for (const i in order.get('item_orders')) {
      await order.get('item_orders')[i].fetch()
    }
    commit('ORDERS_UPDATED', order)
    Promise.all([
      dispatch('getTableOrders', order.get('table')),
      dispatch('payments/getOrderPayments', order, { root: true }),
      dispatch('payments/getRefunds', order, { root: true })
    ]).then(() => {
      dispatch('checkOrderFinished', order)
      commit('ORDERS_LOADING', false)
    }).finally(() => {
      commit('ORDERS_LOADING', false)
    })
  })
  subscription.on('delete', (order) => {
    commit('ORDERS_MODE_SET', false)
    dispatch('destroy')
    commit('ORDERS_LOADING', false)
  })

  commit('ORDERS_SUBSCRIPTION_ADD', subscription)
}

export async function setMode ({ commit }, mode) {
  commit('ORDERS_MODE_SET', mode)
}

// deprecated
export async function refresh ({ commit, dispatch, state }, loggedUser) {
  commit('ORDERS_LOADING', true)
  commit('ORDERS_SET_CLIENT_NAME', false)
  const id = LocalStorage.getItem('order.staffOrder')

  let query

  if (id) {
    query = new Parse.Query('RestaurantOrderSummary')
      .include(['item_orders', 'client', 'business', 'general_items'])
      .equalTo('objectId', id)

    query.include('client')
  } else {
    query = new Parse.Query('RestaurantOrderSummary')
      .include(['item_orders', 'client', 'business', 'general_items'])
      .equalTo('client', Parse.User.current())
      .notEqualTo('closed_by_admin', true)
  }

  if (process.env.BUSINESS_ID && process.env.BUSINESS_ID.length) {
    query.equalTo('business', ({
      __type: 'Pointer',
      className: 'Business',
      objectId: process.env.BUSINESS_ID
    }))
  }

  if (id) {
    return query.find()
      .then(async orders => {
        const order = orders[0]

        if (order && order.get('general_items') && order.get('general_items').length) {
          await Parse.Object.fetchAll(order.get('general_items'))
        }

        commit('ORDERS_OBJECT_SET', order)

        // save client name
        const clientName = order.get('extra_client_name')
        const client = order.get('client')
        if (clientName) {
          commit('ORDERS_SET_CLIENT_NAME', clientName)
        } else if (client) {
          commit('ORDERS_SET_CLIENT_NAME', client.get('name'))
        } else {
          commit('ORDERS_SET_CLIENT_NAME', false)
        }

        if (order.get('take_away')) {
          commit('ORDERS_MODE_SET', (order.get('delivery_address') && order.get('delivery_address').length) ? 'delivery' : 'take_away')
        } else {
          commit('ORDERS_MODE_SET', 'eat_in')
        }

        dispatch('checkWaiterCalled', order)

        if (order.get('table') && order.get('table').id) {
          dispatch('tables/getOne', order.get('table').id, { root: true }).then(() => {
            dispatch('tables/set', order.get('table'), { root: true })
          })
        }

        Parse.Object.fetchAll(order.get('item_orders')).then(async () => {
          dispatch('items/getOrderItemDetails', order.get('item_orders'), { root: true }).then(() => {
            dispatch('startSubscription')

            dispatch('trackAppState')
          })
        })

        // set business from order
        // await dispatch('business/set', order.get('business'), { root: true })
        commit('ORDERS_LOADING', false)
      }, ({ code, message }) => {
        dispatch('destroy')
        commit('ORDERS_LOADING', false)
        console.log('#### Order object restore error (' + code + '):', message)
      })
  } else {
    // filter paid, delivered and rated order
    return query.descending('createdAt')
      .limit(1)
      .find()
      .then(async orders => {
        if (
          orders[0] &&
        !(
          orders[0].get('paid') &&
          orders[0].get('item_orders').length === orders[0].get('item_orders_delivered').length
        )
        ) {
          const order = orders[0]

          if (order.get('general_items') && order.get('general_items').length) {
            await Parse.Object.fetchAll(order.get('general_items'))
          }

          commit('ORDERS_OBJECT_SET', order)

          if (order.get('take_away')) {
            commit('ORDERS_MODE_SET', (order.get('delivery_address') && order.get('delivery_address').length) ? 'delivery' : 'take_away')
          } else {
            commit('ORDERS_MODE_SET', 'eat_in')
          }

          dispatch('checkWaiterCalled', order)

          if (order.get('table') && order.get('table').id) {
            dispatch('tables/getOne', order.get('table').id, { root: true }).then(() => {
              dispatch('tables/set', order.get('table'), { root: true })
            })
          }

          Parse.Object.fetchAll(order.get('item_orders')).then(async () => {
            dispatch('items/getOrderItemDetails', order.get('item_orders'), { root: true }).then(() => {
              dispatch('startSubscription')
              dispatch('trackAppState')
            })
          })
          // set business from order
          // await dispatch('business/set', order.get('business'), { root: true })
        } else {
          dispatch('destroy', false)
        }
        commit('ORDERS_LOADING', false)
      }, ({ code, message }) => {
        dispatch('destroy')
        commit('ORDERS_LOADING', false)
        console.log('#### Order object restore error (' + code + '):', message)
      })
  }
}

export async function create ({ commit, dispatch, rootState, state }, { itemOrders, table = false }) {
  let platform = 'cl_web'
  if (Platform.is.capacitor) {
    if (Platform.is.ios) {
      platform += '_ios'
    } else if (Platform.is.android) {
      platform += '_and'
    }
  }
  const order = await new Parse.Object('RestaurantOrderSummary', Object.assign({
    business: rootState.business.business,
    client: Parse.User.current(),
    created_from: platform,
    longest_goal_time: 10,
    rated: false,
    discount_amount: 0,
    discount_percentage: 0,
    guests: 1,
    item_orders_delivered: [],
    item_orders_in_progress: [],
    item_orders_ready: [],
    paid: false,
    special_note: '',
    special_request: '',
    start_time: new Date(),
    table: {
      __type: 'Pointer',
      className: 'Table',
      objectId: (table) ? table.id : process.env.TAKE_AWAY_TABLE_ID
    },
    take_away: (state.mode !== 'eat_in'),
    tip: 0,
    extra_client_name: ''
  }, state.data)).save()

  if (state.mode === 'eat_in') {
    await new Parse.Query('Waiter')
      .equalTo('business', rootState.business.business)
      .find()
      .then(waiters => {
        if (waiters.length) {
          for (const i in waiters) {
            for (const j in waiters[i].get('tables')) {
              if (waiters[i].get('tables')[j].id === order.get('table').objectId) {
                order.set('waiter', waiters[i])
                order.set('order_waiter', waiters[i])
                break
              }
            }
            if (order.waiter) {
              break
            }
          }
        }
      })
      .catch(error => {
        console.log('#### Waiter from table error:', error)
      })

    for (const i in itemOrders) {
      itemOrders[i].set('restaurant_order_summary', order)

      if (!itemOrders[i].order_waiter && order.get('waiter')) {
        itemOrders[i].set('order_waiter', order.get('waiter'))
      }

      itemOrders[i].set('table', order.get('table'))
    }
  }

  await Parse.Object.saveAll(itemOrders)
  await order.set('item_orders', itemOrders).save()
  commit('ORDERS_SET_DATA')
  commit('ORDERS_OBJECT_SET', order)
  dispatch('startSubscription')

  dispatch('trackAppState')
  return order
}

export async function update ({ commit, state }, { data, order = false, save = false }) {
  commit('ORDERS_LOADING', true)

  if (!order) {
    order = state.current
  }

  for (const i in data) {
    order.set(i, data[i])
  }

  if (save) {
    await order.save()
  }

  commit('ORDERS_UPDATED', order)
  commit('ORDERS_LOADING', false)
}

export async function deleteCurrent ({ dispatch, state }, refresh = true) {
  if (state.current) {
    await state.current.destroy()
  }

  dispatch('destroy', refresh)
}
export async function destroy ({ commit, state }, refresh = true) {
  if (state.hasWaiterCall) {
    state.waiterCall.destroy()
  }

  commit('ORDERS_DESTROY', refresh)
}

export async function setData ({ commit }, data) {
  commit('ORDERS_SET_DATA', data)
}

export async function getTableOrders ({ commit, dispatch, state }, table) {
  commit('ORDERS_TABLE_ORDERS_LOADING', true)
  new Parse.Query('RestaurantOrderSummary')
    .equalTo('table', table)
    .notEqualTo('paid', true)
    .notEqualTo('closed_by_admin', true)
    .include(['client', 'item_orders.restaurant_item'])
    .find()
    .then(async tableOrders => {
      const promises = []
      for (const i in tableOrders) {
        if (tableOrders[i].get('general_items') && tableOrders[i].get('general_items').length) {
          promises.push(Parse.Object.fetchAll(tableOrders[i].get('general_items')))
        }
        commit('ORDERS_REMOVE_ALL')
        Promise.all(promises).then(() => {
          for (const i in tableOrders) {
            commit('ORDERS_SAVE_ONE', tableOrders[i])
          }
        })
      }

      // Notify.create({
      //   type: 'warning',
      //   message: i18n.t('order.tracker.refresh_alert')
      // })
      commit('ORDERS_TABLE_ORDERS_LOADING', false)
    }).catch(e => {
      console.log('#### Orders get table orders error:', e)
    })
}

export function addItems ({ commit, dispatch, state }, items) {
  const newItems = state.current.get('item_orders').concat(items)
  state.current.set('item_orders', newItems)
  return state.current.save()
}

export function browse ({ commit }, user) {
  commit('ORDERS_LOADING', true)
  const query = new Parse.Query('RestaurantOrderSummary').equalTo('client', Parse.User.current())

  // if (process.env.BUSINESS_ID && process.env.BUSINESS_ID.length) {
  //   query.equalTo('business', ({
  //     __type: 'Pointer',
  //     className: 'Business',
  //     objectId: process.env.BUSINESS_ID
  //   }))
  // }

  query.include(['general_items', 'client', 'business'])

  return query.descending('createdAt').find()
    .then(orders => {
      // console.log('browse all', orders, user)
      for (const i in orders) {
        commit('ORDERS_SAVE_ONE', orders[i])
      }
      commit('ORDERS_LOADING', false)
    })
}

export function read ({ commit }, id) {
  commit('ORDERS_LOADING', true)
  return new Parse.Query('RestaurantOrderSummary')
    .include(['client'])
    .get(id)
    .then(order => {
      const clientName = order.get('extra_client_name')
      if (clientName) {
        commit('ORDERS_SET_CLIENT_NAME', clientName)
      }
      commit('ORDERS_SAVE_ONE', order)
      commit('ORDERS_LOADING', false)
    })
}

export async function fetchCurrent ({ commit, state }) {
  commit('ORDERS_UPDATED', await state.current.fetch())
}

export async function createByStaff ({ commit, dispatch, rootState, state }, { itemOrders, table = false }) {
  let platform = 'cl_web'
  if (Platform.is.capacitor) {
    if (Platform.is.ios) {
      platform += '_ios'
    } else if (Platform.is.android) {
      platform += '_and'
    }
  }

  const order = await new Parse.Object('RestaurantOrderSummary', Object.assign({
    business: rootState.business.business,
    created_from: platform,
    longest_goal_time: 10,
    rated: false,
    discount_amount: 0,
    discount_percentage: 0,
    guests: 1,
    item_orders_delivered: [],
    item_orders_in_progress: [],
    item_orders_ready: [],
    paid: false,
    special_note: '',
    special_request: '',
    start_time: new Date(),
    table: {
      __type: 'Pointer',
      className: 'Table',
      objectId: (table) ? table.id : process.env.TAKE_AWAY_TABLE_ID
    },
    take_away: (state.mode !== 'eat_in'),
    tip: 0,
    extra_client_name: LocalStorage.getItem('order.client_name') ?? ''
  }, state.data)).save()

  const orderWaiter = await dispatch('getWaiterForCurrentStaffUser')
  if (orderWaiter) {
    order.set('order_waiter', orderWaiter)
  }
  // console.log('order waiter', orderWaiter)
  // console.log('order', order)

  if (state.mode === 'eat_in') {
    await new Parse.Query('Waiter')
      .equalTo('business', rootState.business.business)
      .find()
      .then(waiters => {
        if (waiters.length) {
          for (const i in waiters) {
            for (const j in waiters[i].get('tables')) {
              if (waiters[i].get('tables')[j].id === order.get('table').objectId) {
                order.set('waiter', waiters[i])
                break
              }
            }
            if (order.waiter) {
              break
            }
          }
        }
      })
      .catch(error => {
        console.log('#### Waiter from table error:', error)
      })

    for (const i in itemOrders) {
      itemOrders[i].set('restaurant_order_summary', order)

      itemOrders[i].set('order_waiter', orderWaiter)

      itemOrders[i].set('table', order.get('table'))
    }
  }

  await Parse.Object.saveAll(itemOrders)
  await order.set('item_orders', itemOrders).save()
  commit('ORDERS_SET_DATA')
  commit('ORDERS_OBJECT_SET', order)
  return order
}

export async function getWaiterForCurrentStaffUser ({ rootState }) {
  let waiter = null
  const staffQuery = new Parse.Query('Waiter')
  staffQuery.equalTo('user', Parse.User.current())
  staffQuery.equalTo('business', rootState.business.business)
  staffQuery.include('business')
  staffQuery.include('user')
  staffQuery.include('tables')
  staffQuery.include('tables' + '.' + 'business')
  await staffQuery.first()
    .then(function (result) {
      // console.log('Found relevant waiter for user', result)
      // var thisWaiter = waiterFromKitchen
      // console.log('continue order')
      waiter = result
    }, error => {
      console.log('Didnt find relevant waiter for user', error)
      const staffQuery = new Parse.Query('Kitchen')
      staffQuery.equalTo('user', Parse.User.current())
      staffQuery.equalTo('business', rootState.business.business)
      staffQuery.include('business')
      staffQuery.first().then(function (kitchen) {
        // console.log('Create relevant waiter for user')
        // console.log('Save a new waiter from relevant kitchen for user')
        const newWaiterFromKitchen = new Parse.Object('Waiter')
        newWaiterFromKitchen.set('name', kitchen.get('name').length() > 0 ? kitchen.get('name') : 'Kitchen User')
        newWaiterFromKitchen.set('user', Parse.User.current())
        newWaiterFromKitchen.set('gender', kitchen.get('gender'))
        newWaiterFromKitchen.set('color', kitchen.get('color'))

        if (kitchen.get('image') != null) { newWaiterFromKitchen.set('image', kitchen.get('image')) }

        newWaiterFromKitchen.set('business',
          kitchen.get('business'))

        newWaiterFromKitchen.save()
          .then(function (result) {
            // console.log('Saved relevant waiter for user')
            // var thisWaiter = newWaiterFromKitchen
            // console.log('continue order')
            waiter = newWaiterFromKitchen
          }, function (error) {
            console.warn('###Save relevant waiter for user error: ' + error)
            return null
          })
      })
    })

  return waiter
}

export async function setRefundedShown ({ state, commit, dispatch }, item) {
  item.set('refund_notified', true)

  await item.save()

  commit('DELETE_REFUNDED', item.id)
}

export async function getOrderById ({ state, commit, dispatch }, orderId) {
  const query = new Parse.Query('RestaurantOrderSummary')

  const order = await query.get(orderId)
  return order
}

export async function setCurrentOrder({ state, commit, dispatch }, order) {
	commit('ORDERS_OBJECT_SET', order)

	if (order.get('take_away')) {
		commit(
			'ORDERS_MODE_SET',
			order.get('delivery_address') && order.get('delivery_address').length
				? 'delivery'
				: 'take_away'
		)
	} else {
		commit('ORDERS_MODE_SET', 'eat_in')
	}

  dispatch('checkWaiterCalled', order)

  if (order.get('table') && order.get('table').id) {
    dispatch('tables/getOne', order.get('table').id, { root: true }).then(() => {
      dispatch('tables/set', order.get('table'), { root: true })
    })
  }

  Parse.Object.fetchAll(order.get('item_orders')).then(async () => {
    dispatch('items/getOrderItemDetails', order.get('item_orders'), { root: true }).then(() => {
      dispatch('startSubscription')
      dispatch('trackAppState')
    })
  })
}

export async function restore({ state, commit, dispatch }, businessId) {
  commit('ORDERS_LOADING', true)
  commit('ORDERS_SET_CLIENT_NAME', false)

  let orderId = LocalStorage.getItem('order.staffOrder')

  if(!orderId) {
    orderId = state.orderId
  }
  if(orderId) {
    const query = new Parse.Query('RestaurantOrderSummary')
      .include(['item_orders', 'client', 'business', 'general_items'])
      .equalTo('objectId', orderId)

    query.include('client')

    const orders = await query.find()

    const order = orders[0]

    // console.log('order', order, order.get('business').id === businessId)
    if(order && order.get('business').id === businessId) {
      if (order && order.get('general_items') && order.get('general_items').length) {
        await Parse.Object.fetchAll(order.get('general_items'))
      }
      commit('ORDERS_OBJECT_SET', order)

      // save client name
      const clientName = order.get('extra_client_name')
      const client = order.get('client')
      if (clientName) {
        commit('ORDERS_SET_CLIENT_NAME', clientName)
      } else if (client) {
        commit('ORDERS_SET_CLIENT_NAME', client.get('name'))
      } else {
        commit('ORDERS_SET_CLIENT_NAME', false)
      }

      if (order.get('take_away')) {
        commit('ORDERS_MODE_SET', (order.get('delivery_address') && order.get('delivery_address').length) ? 'delivery' : 'take_away')
      } else {
        commit('ORDERS_MODE_SET', 'eat_in')
      }
      
      dispatch('checkWaiterCalled', order)
  
      if (order.get('table') && order.get('table').id) {
        dispatch('tables/getOne', order.get('table').id, { root: true }).then(() => {
          dispatch('tables/set', order.get('table'), { root: true })
        })
      }
  
      Parse.Object.fetchAll(order.get('item_orders')).then(async () => {
        dispatch('items/getOrderItemDetails', order.get('item_orders'), { root: true }).then(() => {
          dispatch('startSubscription')
          
          dispatch('trackAppState')
        })
      })
    }
  }
  commit('ORDERS_LOADING', false)
}
