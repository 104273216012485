import { mapState } from 'vuex'

export default {
  methods: {
    addCurrency (price = '...') {
      return this.currency ? this.currencyPrefix + price + this.currencySuffix : price
    }
  },
  computed: {
    ...mapState({
      currency: state => state.business.currency
    }),
    currencyPrefix () {
      return this.currency ? this.currency.get('prefix') : ''
    },
    currencySuffix () {
      return this.currency ?  this.currency.get('suffix') : ''
    },
  },

}
