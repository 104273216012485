export function all (state) {
  return state.all
}

export function loading (state) {
  return state.loading
}

export function current (state) {
  return state.current
}

export const byId = (state) => (categoryId) => {
  for (const i in state.all) {
    if (state.all[i].id === categoryId) {
      return state.all[i]
    }
  }
  return false
}

export const byTitle = (state) => (categoryTitle) => {
  for (const i in state.all) {
    if (state.all[i].get('title') === categoryTitle) {
      return state.all[i]
    }
  }
  return false
}

export const getCountedItems = (state) => (categorId) => {
  return state.countedItems[categorId]
}

export const filteredCategories = (state) => {
  const result = []

  if (state.all) {
    state.all.forEach(category => {
      const isHidden = category.get('hide')
      const hasItems = state.countedItems[category.id] > 0

      if (!isHidden && hasItems) {
        result.push(category)
      }
    });
  }

  return result
}
