import { LocalStorage } from 'quasar'

let businessId = false
if (process.env.BUSINESS_ID && process.env.BUSINESS_ID.length) {
  businessId = process.env.BUSINESS_ID
} else if (LocalStorage.has('business.businessId')) {
  businessId = LocalStorage.getItem('business.businessId')
}

export default function () {
  return {
    all: [],
    businessId: businessId,
    business: false,
    menus: false,
    hours: false,
    clock: false,
    loading: false,
    isOpenAllDay: false,
    currency: false
  }
}
