import Vue from 'vue'

export function TABLES_LOADING (state, status) {
  Vue.set(state, 'loading', status)
}

export function TABLES_SET_SELECTED (state, table) {
  Vue.set(state, 'selected', table)
}

export function TABLES_SET_ORDERS_TABLE (state, table) {
  Vue.set(state, 'table', table)
}

export function TABLES_SET (state, tables) {
  Vue.set(state, 'all', tables)
}

export function TABLES_ORDERS_SET (state, { tableId, tableOrders }) {
  Vue.set(state.orders, tableId, tableOrders)
}
