import { Parse } from "src/boot/parse";

export function calculatePrice(ctx, items) {
  let totalPrice = 0;
  const currentDate = new Date();
  for (const i in items) {
    if (
      items[i].get("restaurant_item").get("price_special") &&
      currentDate <
        new Date(
          items[i].get("restaurant_item").get("price_special_expiration")
        )
    ) {
      totalPrice += items[i].get("restaurant_item").get("price_special");
    } else {
      totalPrice += items[i].get("restaurant_item").get("price");
    }
    totalPrice += items[i].get("additional_price");
  }

  return totalPrice;
}

export async function getAutocomplete({ state, commit, rootState, rootGetters }) {
  // console.log('act', rootGetters['categories/filteredCategories'])
  let activeCategoriesIds = []
  if (rootGetters['categories/filteredCategories'].length > 0) {
    // console.log('act', rootGetters['categories/filteredCategories'])
    activeCategoriesIds = rootGetters['categories/filteredCategories'].map(category => category.id)
  } else {
    // commit("ITEMS_SAVE_AUTOCOMPLETE", [])
    return false
  }
  // console.log('act ids', activeCategoriesIds)
  const query = new Parse.Query("RestaurantItem")
    .equalTo("business", rootState.business.business)
    .notEqualTo('archive', true)
    .equalTo('visible', true)
    .select("title")
    .select("visible")
    .select('categories')

  const TA = 'take_away'
  if (rootState.orders.mode === TA || rootState.orders.mode === 'delivery') {
    query.equalTo(TA, true)
  }

  await query
    .find()
    .then((items) => {
      let activeItems = []
      if (activeCategoriesIds.length > 0) {
        items.forEach(item => {
          const hasActiveCategory = item.get('categories').some(category => activeCategoriesIds.includes(category))

          if (hasActiveCategory) {
            activeItems.push(item)
          }
        })
        // console.log('auto', activeItems)
      } else {
        activeItems = items
      }
      commit("ITEMS_SAVE_AUTOCOMPLETE", activeItems);
    });
}

export async function read({ state, commit }, itemId) {
  commit("ITEMS_LOADING", true);

  if (!itemId) {
    return {};
  }

  return await new Parse.Query("RestaurantItem")
    .get(itemId)
    .then((item) => {
      commit("ITEMS_SAVE_ONE", item);
    })
    .catch((error) => {
      console.log("#### Items read error:", error);
    })
    .then(() => {
      commit("ITEMS_LOADING", false);
    });
}

export async function getSpecials({ state, commit, rootState }) {
  if (!Object.keys(state.specials).length) {
    commit("ITEMS_LOADING", true);
    const now = new Date()

    return await new Parse.Query("RestaurantItem")
      .equalTo("business", rootState.business.business)
      .notEqualTo('archive', true)
      .equalTo('visible', true)
      .exists("price_special_expiration", now)
      .greaterThanOrEqualTo("price_special_expiration", now)
      .containedIn("menus", rootState.business.menus)
      .exists("price_special", now)
      .include("categories")
      .ascending("title")
      .find()
      .then((items) => {
        commit("ITEMS_SAVE_SPECIALS", items);
      })
      .catch((error) => {
        console.log("#### Items getSpecials error:", error);
      })
      .then(() => {
        commit("ITEMS_LOADING", false);
      });
  }
}
// TODO: Live Query doesn’t handle relations. You could do an array of ObjectIds as a work around
// https://github.com/parse-community/parse-server/issues/5610#issuecomment-495277400

// export async function getLiveSpecials({ state, commit, dispatch, rootState }) {
//   console.log('get live', Object.keys(state.specials).length)
//   if (!Object.keys(state.specials).length) {
//     commit("ITEMS_LOADING", true);

//     const query = new Parse.Query("RestaurantItem")
//       .equalTo("business", rootState.business.business)
//       .include("categories")
//       .ascending("title")

//     const sub = await query.subscribe()
//     console.log('subscribed!')
//     sub.on('create', (items) => {
//       console.log('object entered', items);
//       commit("ITEMS_SAVE_SPECIALS", items);
//       commit("ITEMS_LOADING", false);
//     });
//     sub.on('open', (info) => {
//       console.log('object entered', info);
//       dispatch('getSpecials')
//       // commit("ITEMS_SAVE_SPECIALS", items);
//       commit("ITEMS_LOADING", false);
//     });
//     sub.on('update', (items) => {
//       commit("ITEMS_SAVE_SPECIALS", items);
//       console.log('object updated', items);
//       commit("ITEMS_LOADING", false);
//     });

// .then((items) => {
//   commit("ITEMS_SAVE_SPECIALS", items);
// })
// .catch((error) => {
//   console.log("#### Items getSpecials error:", error);
// })
// .then(() => {
//   commit("ITEMS_LOADING", false);
// });
//   }
// }

export function getCategoryItems(
  { state, commit, rootState },
  { categoryId, activeMenus }
) {
  commit("ITEMS_LOADING", true);

  const query = new Parse.Query("RestaurantItem")
    .ascending("title")
    .notEqualTo('archive', true)
    .equalTo('visible', true)
    .containedIn("categories", [categoryId]);

  if (activeMenus !== undefined || !activeMenus.length) {
    query.containedIn("menus", activeMenus);
  }

  // Filter items for TA mode
  const TA = 'take_away'
  if (rootState.orders.mode === TA || rootState.orders.mode === 'delivery') {
    query.equalTo(TA, true)
  }

  return query
    .find()
    .then((items) => {
      commit("ITEMS_SET_FOR_CATEGORY", {
        items: items,
        categoryId: categoryId,
      });
    })
    .catch((error) => {
      console.log("#### Items error:", error);
    })
    .then(() => {
      commit("ITEMS_LOADING", false);
    });
}

export async function getBusinessItems ({ state, commit, rootState }, { activeMenus, activeCategories = false }) {
  commit('ITEMS_LOADING', true)

  // console.log('id', rootState.business.business)

  const categories = activeCategories || rootState.categories.all.map(cat => cat.id)
  // console.log('cats', categories)

  const query = new Parse.Query("RestaurantItem")
    .equalTo("business", rootState.business.business)
    .notEqualTo('archive', true)
    .equalTo('visible', true)
    .ascending("title");

  // Download objects of the pointers
  query.include('extras_group')

  if (activeMenus !== undefined || !activeMenus.length) {
    query.containedIn('menus', activeMenus)
  }
  try {
    const items = await query.find()
    // console.log('action items', items)

    categories.forEach(category => {
      // eslint-disable-next-line prefer-const
      let catItems = []

      items.forEach(item => {
        const itemCategories = item.get('categories')
        if (itemCategories.includes(category)) {
          catItems.push(item)
        }
      })
      commit('ITEMS_SET_FOR_CATEGORY', { items: catItems, categoryId: category })
    })

    // items.forEach(item => {
    //   commit('ITEMS_SAVE_ONE', item)
    //   const categories = item.get('categories')
    //   console.log('cats', item, categories)
    //   categories.forEach(category => {
    //     category && commit('ITEMS_SET_FOR_CATEGORY', { items: [item], categoryId: category })
    //   })
    // })
  } catch (error) {
    console.log("#### Items error:", error);
  }
  commit("ITEMS_LOADING", false);
}

export async function getWarnings({ state, commit }) {
  if (!Object.keys(state.warnings).length) {
    commit("ITEMS_WARNINGS_LOADING", true);
    return await new Parse.Query("Warning")
      .find()
      .then((warnings) => {
        commit("ITEMS_SET_WARNINGS", warnings);
      })
      .catch((error) => {
        console.log("#### Warnings error:", error);
      })
      .then(() => {
        commit("ITEMS_WARNINGS_LOADING", false);
      });
  }
}

export async function getItemExtras({ state, commit }, item) {
  commit("ITEMS_EXTRAS_LOADING", true);

  const extrasIdsArray = [];
  if (item && item.attributes) {
    const extras = item.get("extras");

    const groups = item.get('extras_group')

    for (const j in groups) {
      const extras = groups[j].get('extras_group')

      for (const n in extras) {
        extrasIdsArray.push(extras[n].id || extras[n].objectId)
      }
    }

    for (const i in extras) {
      if (extras[i].groupOptions?.length > 0) {
        // Add options(extras) from groupOptions as well
        for (const extra of extras[i].groupOptions) {
          extrasIdsArray.push(extra.id || extra.objectId);
        }
      } else {
        extrasIdsArray.push(extras[i].id || extras[i].objectId);
      }
    }

    return new Parse.Query("RestaurantItemExtra")
      .containedIn("objectId", extrasIdsArray)
      .find()
      .then((extras) => {
        commit("ITEMS_SET_ITEM_EXTRAS", {
          itemId: item.id ? item.id : item.objectId ? item.objectId : 'local',
          extras: extras,
        });
      })
      .catch((error) => {
        console.log("#### Item Extras error:", error);
      })
      .then(() => {
        commit("ITEMS_EXTRAS_LOADING", false);
      });
  }
}
export async function getGroupItemExtras({ state, commit }, item) {
  commit("ITEMS_EXTRAS_LOADING", true);

  const extrasIdsArray = [];
  if (item && item.attributes) {
    const extras = item.get("extras_group");
    for (const i in extras) {
      const extras_group = extras[i]?.get('extras_group')
      if (extras_group && extras_group.length > 0) {
        // Add options(extras) from extras_group as well
        for (const extra of extras_group) {
          extrasIdsArray.push(extra.id || extra.objectId);
        }
      } else {
        extrasIdsArray.push(extras[i].id || extras[i].objectId);
      }
    }

    return new Parse.Query("RestaurantItemExtraGroup")
      .containedIn("objectId", extrasIdsArray)
      .include('extras_group')
      .find()
      .then((extras) => {
        commit("ITEMS_SET_ITEM_EXTRAS", {
          itemId: item.id ? item.id : item.objectId,
          extras: extras,
        });
      })
      .catch((error) => {
        console.log("#### Item Extras error:", error);
      })
      .then(() => {
        commit("ITEMS_EXTRAS_LOADING", false);
      });
  }
}

export async function getOrderItemDetails({ state, commit }, items) {
  if (items.length) {
    items = await Parse.Object.fetchAll(items);

    for (const i in items) {
      items[i] = items[i].get("restaurant_item");
    }

    items = await Parse.Object.fetchAll(items);

    for (const i in items) {
      commit("ITEMS_SAVE_ONE", items[i]);
    }
  }
}

export async function saveBatch({ commit, dispatch }, items) {
  for (const i in items) {
    items[i] = await dispatch("cart/parsePrototype", items[i], { root: true });
  }
  Parse.Object.saveAll(items).then((result) => {
    commit("ITEMS_SAVE_BATCH", result);
  });
}
