export default [
  {
    path: '',
    name: 'home',
    component: () => import('src/pages/app/Landing.vue'),
    meta: {
      sideMenuShadow: false,
      allowEmptyOrder: true,
      redirectActiveOrder: true
    }
  }
]
