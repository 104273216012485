import { LocalStorage, Notify } from 'quasar'
import { i18n } from 'src/boot/i18n'
import Vue from 'vue'

export function ORDERS_TABLE_ORDERS_LOADING (state, status) {
  state.tableOrdersLoading = status
}

export function ORDERS_SET_DATA (state, data = false) {
  if (data) {
    data = Object.assign(state.data, data)
    Vue.set(state, 'data', data)
    LocalStorage.set('order.data', data)
  } else {
    Vue.set(state, 'data', {})
    LocalStorage.remove('order.data')
  }
}

export async function ORDERS_SET_WAITER_CALL (state, waiterCall) {
  Vue.set(state, 'waiterCall', waiterCall || false)
  Vue.set(state, 'hasWaiterCall', waiterCall ? waiterCall.get('call_waiter') : false)
  Vue.set(state, 'hasCashCall', waiterCall ? waiterCall.get('pay_waiter') : false)
  Vue.set(state, 'waiterCallId', waiterCall ? waiterCall.id : false)

  LocalStorage.set('order.call_id', waiterCall ? waiterCall.id : false)
}

export function ORDERS_ADD_ITEMS (state, items) {
  state.current.set('item_orders', items)
}

export function ORDERS_LOADING (state, status) {
  Vue.set(state, 'loading', status)
}

export function ORDERS_DESTROY (state, refresh = true) {
  Vue.set(state, 'orderId', false)
  LocalStorage.remove('order.id')

  Vue.set(state, 'data', {})
  LocalStorage.remove('order.data')
  LocalStorage.remove('order.call_id')

  Vue.set(state, 'current', false)
  Vue.set(state, 'subscription', false)
  Vue.set(state, 'socketUpdateFlag', false)
  Vue.set(state, 'waiterCall', false)
  Vue.set(state, 'waiterCallId', false)
  Vue.set(state, 'hasWaiterCall', false)
  Vue.set(state, 'loading', false)

  if (refresh) {
    window.location.reload()
  }
}

export function ORDERS_UPDATED (state, order) {
  Vue.set(state, 'socketUpdateFlag', !state.socketUpdateFlag)
  if (state.current && state.current.id && state.current.id === order.id) {
    Vue.set(state, 'current', order)
  }
}

export function ORDERS_OBJECT_SET (state, order) {
  Vue.set(state, 'current', order)
  Vue.set(state, 'orderId', order.id)
  LocalStorage.set('order.id', order.id)
}

export function ORDERS_SUBSCRIPTION_ADD (state, subscription) {
  Vue.set(state, 'subscription', subscription)
}

export function ORDERS_MODE_SET (state, mode) {
  Vue.set(state, 'mode', mode)
  LocalStorage.set('order.mode', mode)
}

export function ORDERS_SAVE_ONE (state, order) {
  let found = false

  for (const i in state.all) {
    if (order.id === state.all[i].id) {
      found = true
      break
    }
  }

  if (!found) {
    state.all.push(order)
  }
}

export function ORDERS_REMOVE_ALL (state) {
  Vue.set(state, 'all', [])
}

export function ORDERS_SET_ROLE (state, role) {
  Vue.set(state, 'role', role)
}

export function ORDERS_SET_CLIENT_NAME (state, name) {
  LocalStorage.set('order.client_name', name)
  Vue.set(state, 'extra_client_name', name)
}

export function ORDERS_SET_SHOW_POPUP (state, bool) {
  Vue.set(state, 'showClientNamePopup', bool)
}

export function SHOW_REFUNDED (state, item) {
  Vue.set(state.refundeds, item.id, item)
}

export function SHOW_ACTIVES (state, payload) {
  state.showActives = payload
  LocalStorage.set('order.show_actives', payload)
}
export function DELETE_REFUNDED (state, itemId) {
  Vue.delete(state.refundeds, itemId)
}
