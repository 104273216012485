import { Parse } from 'src/boot/parse'
import { loadStripe } from '@stripe/stripe-js/pure';

const preparePaymentData = (amount, tip, order, businessId, altBuyerKey = false, newCreditCard = false, method = false) => {
  const paymentData = {
    amount: amount,
    businessId: businessId,
    clientId: Parse.User.current().id,
    isDebug: process.env.Parse.ENV === 'development',
    productType: 1,
    productId: order.id,
    special_note: '',
    tip: tip
  }

  if (altBuyerKey !== false) {
    paymentData.altBuyerKey = altBuyerKey
  }

  if (newCreditCard !== false) {
    paymentData.newCreditCard = true
  }
  if (method !== false) {
    paymentData.salePaymentMethod = method
  }

  return paymentData
}

export function closeIframe ({ commit }) {
  commit('PAYMENTS_IFRAME_URL_SET', '')
}

export function payCreditCard ({ commit, dispatch }, { amount, tip, order, businessId, altBuyerKey = false, newCreditCard = false, method = false }) {
  commit('PAYMENTS_LOADING', true)
  const paymentData = preparePaymentData(amount, tip, order, businessId, altBuyerKey, newCreditCard, method)

  return new Promise((resolve, reject) => {
    Parse.Cloud
      .run('purchaseProduct', paymentData)
      .then(async response => {
        if (response.indexOf('https://') === 0) {
          commit('PAYMENTS_IFRAME_URL_SET', response)
        } else {
          if (response === 'success') {
            dispatch('getOrderPayments', order)
            resolve(response)
          } else {
            console.log('#### Payment error:', response)
            reject(response)
          }
        }
      })
      .catch((e) => {
        console.log('#### Payment error:', e)
        reject(e)
      })
      .then(() => {
        commit('PAYMENTS_LOADING', false)
      })
  })
}

// TODO: If payments only one for order take first
export async function getOrderPayments ({ commit }, order) {
  commit('PAYMENTS_LOADING', true)
  await new Parse.Query('Payment')
    .equalTo('restaurant_order_summary', order)
    .include('restaurant_order_summary')
    .find()
    .then(async orderPayments => {
      commit('PAYMENTS_ORDERS_SET', {
        orderId: order.id,
        orderPayments: orderPayments
      })
      const isPaid = order.get('paid')
      const isNotified = order.get('client_popup_payment_viewed')

      if (isPaid && !isNotified) {
        commit('ADD_RECEIPT', { order: order, payment: orderPayments[0] })
      }
    }).catch(e => {
      console.error('#### Get payments error:', e)
    }).then(() => {
      commit('PAYMENTS_LOADING', false)
    })
}

// Get new refunded items
export async function getRefunds ({ commit }, order) {
  const refunds = order.get('item_orders_refunded')
  // console.log('refunds', refunds)
  if (refunds) {
    for (const item of refunds) {
      const orderItem = Parse.Object.extend("RestaurantOrder");
      const query = new Parse.Query(orderItem);
      const currentItem = await query.get(item.id)
      if (currentItem.get('refund_type') && currentItem.get('refund_notified') !== true) {
        commit('orders/SHOW_REFUNDED', currentItem, { root: true })
      }
    }
  }
}

export async function changeStep ({ commit, state }, targetStep) {
  commit('PAYMENTS_CHANGE_STEP', targetStep)
}

export async function markPaymentComplete ({ commit, state }) {
  commit('PAYMENTS_MARK_COMPLETE', true)
  setTimeout(() => {
    commit('PAYMENTS_MARK_COMPLETE', false)
  }, 5000)
}

export async function closeReceipt ({ commit, state, rootGetters }, orderId) {
  const order = await new Parse.Query('RestaurantOrderSummary')
    .get(orderId)

  if (!order) {
    commit('REMOVE_RECEIPT', orderId)
    return true
  }

  order.set('client_popup_payment_viewed', true)
  order.save().then(result => {
    const isSaved = result.get('client_popup_payment_viewed')

    if (isSaved) {
      commit('REMOVE_RECEIPT', orderId)
      return true
    } else {
      console.error('### DD: error save order')
      return false
    }
  })
}

export async function getOrderPrice(_, orderId) {
  const price = await Parse.Cloud.run('calculateOrderPrice', { orderId: orderId })

  return price
}

/** @deprecated */
export async function payByStripe({ commit, state, rootGetters, rootState }, order) {
  const data = {
    account: rootState.business.business.get('stripe_account_id'), 
    businessId: rootState.business.business.id, 
    orderId: order.id, 
    clientId: order.get('client').id,
    tip: parseFloat(order.get('tip').toFixed(2))
  }
  const result = await Parse.Cloud.run('createStripePaymentSession',data)
  window.open(result.session.url)

  return result
}

export async function createStripeSession({ commit, state, rootGetters, rootState }, { order, ui_mode }) {
  const data = {
    account: rootState.business.business.get('stripe_account_id'), 
    businessId: rootState.business.business.id, 
    orderId: order.id, 
    clientId: order.get('client').id,
    tip: parseFloat(order.get('tip').toFixed(2)),
    ui_mode
  }
  const result = await Parse.Cloud.run('createStripePaymentSession',data)
  // console.log('pay session', result.session)

  return { 
    url: result.session.url, 
    clientSecret: result.session.client_secret, 
    error: result.error 
  }
}

export async function createEmbeddedStripeCheckout({ dispatch, commit, state, rootGetters, rootState }, order) {
  const stripeAccount = rootState.business.business.get('stripe_account_id')
  const pk = process.env.Parse.STRIPE_PUBLISHABLE_KEY
  const stripe = await loadStripe(pk, {
    stripeAccount,
 });

 const fetchClientSecret = async () => {
  const result = await dispatch('createStripeSession', { order, ui_mode: 'embedded' })
  return result.clientSecret
 }
 let checkout = null
  // Initialize Checkout
  checkout = await stripe.initEmbeddedCheckout({
    fetchClientSecret,
    onComplete: () => { 
      if(checkout) checkout.destroy()
    }
  });

  // Mount Checkout
  // checkout.mount('#checkout');
  return checkout
};