export default function () {
  return {
    all: {},
    categories: {},
    specials: {},
    loading: false,
    autocomplete: [],
    warnings: {},
    warningsLoading: false,
    extrasByItem: {},
    extras: {},
    extrasLoading: false
  }
}
