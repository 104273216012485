import Vue from 'vue'
import auth from 'src/mixins/auth'
import layout from 'src/mixins/layout'
import responsivity from 'src/mixins/responsivity'
import currency from 'src/mixins/currency'
import { AddressbarColor } from 'quasar'

Vue.mixin(auth)
Vue.mixin(layout)
Vue.mixin(responsivity)
Vue.mixin(currency)

AddressbarColor.set('#003250')
