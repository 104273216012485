import Vue from 'vue'

export function ITEMS_SAVE_ONE (state, item) {
  Vue.set(state.all, item.id, item)
}

export function ITEMS_SAVE_BATCH (state, promise) {}

export function ITEMS_DELETE_ALL (state) {
  Vue.set(state, 'all', {})
}

export function ITEMS_SAVE_AUTOCOMPLETE (state, items) {
  Vue.set(state, 'autocomplete', items)
}

export function ITEMS_SET_FOR_CATEGORY (state, { items, categoryId }) {
  if (!state.categories[categoryId]) {
    state.categories[categoryId] = {}
  }

  Vue.set(state.categories, categoryId, items)

  for (const i in items) {
    Vue.set(state.all, items[i].id, items[i])
  }
}

export function ITEMS_SAVE_SPECIALS (state, items) {
  Vue.set(state, 'specials', items)
}

export function ITEMS_SET_WARNINGS (state, warnings) {
  const warningsById = {}
  for (const i in warnings) {
    warningsById[warnings[i].id] = warnings[i]
  }
  Vue.set(state, 'warnings', warningsById)
}

export function ITEMS_SET_ITEM_EXTRAS (state, { itemId, extras }) {
  Vue.set(state.extrasByItem, itemId, extras)

  for (const i in extras) {
    Vue.set(state.extras, extras[i].id, extras[i])
  }
}

export function ITEMS_LOADING (state, status) {
  Vue.set(state, 'loading', status)
}

export function ITEMS_WARNINGS_LOADING (state, status) {
  Vue.set(state, 'warningsLoading', status)
}

export function ITEMS_EXTRAS_LOADING (state, status) {
  Vue.set(state, 'extrasLoading', status)
}
