import Vue from 'vue'
import { LocalStorage } from 'quasar'

export function AUTH_LOADING (state, status) {
  Vue.set(state, 'loading', status)
}

export function AUTH_SAVING (state, status) {
  Vue.set(state, 'saving', status)
}

export function AUTH_CHECKED (state) {
  Vue.set(state, 'checked', true)
}

export function AUTH_TOKEN_SET (state, token) {
  Vue.set(state, 'token', token)
  LocalStorage.set('auth.token', token)
  LocalStorage.set('auth.freshLogin', true)
}

export function AUTH_VERIFICATION_CODE_SET (state, verificationCode) {
  Vue.set(state, 'verificationCode', verificationCode)
}

export function AUTH_VERIFIED (state) {
  Vue.set(state, 'phoneVerified', true)
}

export function AUTH_SET_LOCATION (state, location) {
  Vue.set(state, 'location', location)
}

export function AUTH_LOGOUT (state) {
  if (state.token.length) {
    LocalStorage.remove('auth.token')
    Vue.set(state, 'token', '')
    window.location.reload()
  }
}

export function AUTH_STORE_ADDRESSES (state, addresses) {
  Vue.set(state, 'addresses', addresses)
}

export function AUTH_STORE_USER (state, user) {
  Vue.set(state, 'user', user)
}

export function SET_PRE_NUMBER (state, payload) {
  state.prePhone = payload.phone
  state.preCountryCode = payload.code
  state.preName = payload.name
  state.preCountry = payload.country
}

export function AUTH_SET_ONBOARD_TIP (state, { key, value } ) {
  Vue.set(state, key, value)
  LocalStorage.set(`onboarding.${key}`, value)
}

