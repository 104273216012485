export function all (state) {
  return state.all
}

// TODO: fix vuex error. sort not in mutations
export const ByDistance = (state) => (userLocation) => {
  const businesses = state.all

  return businesses.sort(function (a, b) {
    const locationA = a.get("location");
    const locationB = b.get("location");

    if (!locationA && !locationB) return 0;
    if (!locationA) return 1;
    if (!locationB) return -1;

    if (
      locationA.kilometersTo(userLocation) <
      locationB.kilometersTo(userLocation)
    ) {
      return -1;
    }
    if (
      locationA.kilometersTo(userLocation) >
      locationB.kilometersTo(userLocation)
    ) {
      return 1;
    }
    return 0;
  });
};

export function currency(state) {
  return state.currency;
}

export function businessId(state) {
  return state.businessId;
}
export function current(state) {
  return state.business;
}
export function loading(state) {
  return state.loading;
}
export function hours(state) {
  return state.hours;
}
export const one = (state) => (id) => {
  for (const i in state.all) {
    if (state.all[i].id === id) {
      return state.all[i];
    }
  }
  return false;
};

export function menus(state) {
  return state.menus;
}

const isInRange = (range, currentTime) =>
  (currentTime.hours > range.from.hours ||
    (currentTime.hours === range.from.hours &&
      currentTime.minutes >= range.from.minutes)) &&
  (currentTime.hours < range.to.hours ||
    (currentTime.hours === range.to.hours &&
      currentTime.minutes <= range.to.minutes));

// TODO: it doesn't check timezone of the user
// Use global time as local time
export function activeMenus(state) {
  if (!state.clock || !state.menus) {
    return [];
  }

  const currentTime = state.clock;
  const result = [];
  let mainMenu;

  if (state.menus.length < 2) {
    result.push(state.menus[0]);
  } else {
    let menuHours = {};

    for (const i in state.menus) {
      menuHours = {
        from: {
          hours: new Date(state.menus[i].attributes.from).getUTCHours(),
          minutes: new Date(state.menus[i].attributes.from).getUTCMinutes(),
        },
        to: {
          hours: new Date(state.menus[i].attributes.to).getUTCHours(),
          minutes: new Date(state.menus[i].attributes.to).getUTCMinutes(),
        },
      };

      if (isInRange(menuHours, currentTime)) {
        // skip main menu
        if (state.menus[i].get("ref") !== "main") {
          result.push(state.menus[i]);
        } else {
          mainMenu = state.menus[i];
        }
      }
    }
  }

  // if no other menu is active add main menu
  if (result.length === 0) {
    result.push(mainMenu);
  }

  return result;
}

// TODO: rename to canGetOrders
// has credits or subscribed
export function hasCredits(state, getters) {
  // console.log('bus', state.business)
  if(getters.isSubscribed) {
    return true
  }
  if (
    !state.business ||
    !state.business.attributes ||
    !Object.keys(state.business.attributes).length ||
    state.business.get("credits_restaurant_items") <= 0
  ) {
    return false;
  }

  return true;
}

export function isSubscribed(state) {
  if(state.business && state.business.attributes && !!state.business.get('business_subscription')) {
    return true
  }

  return false
}

export function isGetStripePayments(state) {
  if(state.business && state.business.attributes && !!state.business.get('stripe_charges_enabled')) {
    return true
  }

  return false
}

export function isOpen(state) {
  if (state.isOpenAllDay) return true;
  if (!state.hours || !state.business || !state.clock) {
    return false;
  }

  if (state.hours.length) {
    // in js dates sunday = 0
    // but the genious developer who built the opening hours object set sunday as 1.
    const now = new Date();
    const day = now.getDay() + 1;

    const currentTime = state.clock;

    if (state.hours[day]) {
      for (const i in state.hours[day]) {
        if (state.hours[day][i].closed) {
          return false;
        }
        if (state.hours[day][i].allDay) {
          return true;
        }
        if (isInRange(state.hours[day][i], currentTime)) {
          return true;
        }
      }
      // if there's no entry for the current day
    } else if (state.hours[0]) {
      for (const i in state.hours[0]) {
        if (isInRange(state.hours[0][i], currentTime)) {
          return true;
        }
      }
    }
  }

  return false;
}

const isBetweenWindows = (window1, window2, currentTime) =>
  (currentTime.hours > window1.to.hours ||
    (currentTime.hours === window1.to.hours &&
      currentTime.minutes >= window1.to.minutes)) &&
  (currentTime.hours < window2.from.hours ||
    (currentTime.hours === window2.from.hours &&
      currentTime.minutes <= window2.from.minutes));

export function nextOpeningWindow(state) {
  const formatTime = (time) =>
    time.hours + ":" + (time.minutes < 10 ? "0" : "") + time.minutes;

  if (state.hours.length) {
    const now = new Date();
    const currentTime = state.clock;
    // in js dates sunday = 0
    // but the genious developer who built the opening hours object set sunday as 1.
    const day = now.getDay() + 1;

    if (state.hours[day]) {
      if (state.hours[day].length > 1) {
        for (const i in state.hours[day]) {
          // if there's another window on the same day
          if (state.hours[day][i + 1]) {
            if (
              isBetweenWindows(
                state.hours[day][i],
                state.hours[day][i + 1],
                currentTime
              )
            ) {
              return formatTime(state.hours[day][i + 1]);
            }
          }
        }
        const nextDay = day === 7 ? 0 : day + 1
        const isAllDayNext = state.hours[nextDay][0].allDay
        return isAllDayNext ? '00:00' : formatTime(state.hours[nextDay][0].from);
      } else {
        const nextDay = day === 7 ? 0 : day + 1
        const isAllDayNext = state.hours[nextDay][0].allDay
        return isAllDayNext ? '00:00' : formatTime(state.hours[nextDay][0].from);
      }

      // if there's another window on the next day
    } else if (day !== 0 && state.hours[day + 1]) {
      return formatTime(state.hours[day + 1][0]);
    } else {
      if (state.hours[0].length > 1) {
        for (const i in state.hours[0]) {
          // if there's another window on the same 0
          if (state.hours[0][i + 1]) {
            if (
              isBetweenWindows(
                state.hours[0][i],
                state.hours[0][i + 1],
                currentTime
              )
            ) {
              return formatTime(state.hours[0][i + 1]);
            }
          }
        }
      } else {
        // TODO: fix: this row case errors, if business open all day
        return formatTime(state.hours[0][0].from);
      }
    }
  }

  return false;
}

export function paymentPlugin(state) {
  if(state.business.get('stripe_account_id')) {
    return 'stripe'
  } else if(state.business.get('payme_seller_id')){
    return 'payme'
  }
  return false
}

export function tableStatus(state) {
  const status = state.business ? state.business.get('table_status') : ''
  return status
}