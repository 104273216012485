export default {
  computed: {
    rtl () {
      const locale = this.$q.lang.isoName || process.env.DEFAULT_LOCALE
      if (locale === 'he' || locale === 'ar') {
        return true
      }
      return false
    }
  }
}
