export default function () {
  return {
    currentItem: false,
    items: [],
    itemsCountById: {},
    loading: false,
    ready: false,
    enabledExtras: []
  }
}
