import Vue from 'vue'

export function ADDRESSES_DELETING (state, status) {
  Vue.set(state, 'deleting', status)
}

export function ADDRESSES_DELETE (state, index) {
  state.all.splice(index, 1)
}

export function ADDRESSES_STORE (state, addresses) {
  Vue.set(state, 'all', addresses)
}

export function ADDRESSES_STORE_ONE (state, address) {
  let found = false
  for (const i in state.all) {
    if (state.all[i].id === address.id) {
      found = true
      Vue.set(state.all, i, address)
    }
  }

  if (!found) {
    state.all.push(address)
  }
}

export function ADDRESSES_LOADING (state, status) {
  Vue.set(state, 'loading', status)
}

export function ADDRESSES_SAVING (state, status) {
  Vue.set(state, 'saving', status)
}

export function ADDRESSES_SELECT (state, address) {
  Vue.set(state, 'selected', address)
}
