import { LocalStorage } from 'quasar'

export default function () {
  return {
    loading: false,
    saving: false,
    user: {},
    checked: false,
    token: LocalStorage.has('auth.token') ? LocalStorage.getItem('auth.token') : '',
    freshLogin: LocalStorage.has('auth.freshLogin') ? LocalStorage.getItem('auth.freshLogin') : false,
    location: false,
    verificationCode: false,
    addresses: false,
    prePhone: null,
    preName: null,
    preCountryCode: null,
    preCountry: null,
    // Array of app languages. Index of array item is lang index in planner. Keep ordering!
    //new lang add here #2/4
    languages: [
      false,
      'en',
      'he',
      'es',
      'de',
      'fr',
      'it',
      'ru',
      'ar'
    ],
    //new lang add here #3/4
    flags: {
      'he': 'il',
      'de': 'de',
      'en': 'us',
      'fr': 'fr',
      'es': 'es',
      'it': 'it',
      'ru': 'ru',
      'ar': 'ae'
    },
   // Onboarding tips:
    category: LocalStorage.has('onboarding.category') ? LocalStorage.getItem('onboarding.category') : true,
    tray: LocalStorage.has('onboarding.tray') ? LocalStorage.getItem('onboarding.tray') : true,
    order: LocalStorage.has('onboarding.order') ? LocalStorage.getItem('onboarding.order') : true,
    tracker: LocalStorage.has('onboarding.tracker') ? LocalStorage.getItem('onboarding.tracker') : true,
    payment: LocalStorage.has('onboarding.payment') ? LocalStorage.getItem('onboarding.payment') : true
  }
}
