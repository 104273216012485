export function table (state) {
  return state.table
}

export function selected (state) {
  return state.selected
}

export function loading (state) {
  return state.loading
}

export function all (state) {
  return [...state.all].sort(function (a, b) {
    const titleA = a.get('title')
    const titleB = b.get('title')

    if (!titleA && !titleB) {
      return 0
    } else if (!titleA || titleA < titleB) {
      return -1
    } else if (!titleB || titleA > titleB) {
      return 1
    }

    return 0
  })
}

export function ordersReady (state) {
  return state.ordersReady
}

export const orders = (state) => (tableId) => {
  return state.orders[tableId]
}
