import { i18n } from 'src/boot/i18n'

export function all (state) {
  return state.all
}

export function loading (state) {
  return state.loading
}

export function saving (state) {
  return state.saving
}

export function deleting (state) {
  return state.deleting
}

export function selected (state) {
  return state.selected
}

export const formatted = (state) => (address = false) => {
  if (!address || !address.attributes) {
    return false
  }

  let result = address.get('street') + ' ' + address.get('number') + ', ' + address.get('city')
  if (address.get('apartment') || address.get('entrance')) {
    result += ' ('

    if (address.get('apartment') && address.get('apartment').length) {
      result += i18n.t('addresses.apartment', { apartment: address.get('apartment') })
      if (address.get('entrance') && address.get('entrance').length) {
        result += ' '
      }
    }

    if (address.get('entrance') && address.get('entrance').length) {
      result += i18n.t('addresses.entrance', { entrance: address.get('entrance') })
    }

    result += ')'
  }

  return result
}
