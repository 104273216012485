import Vue from 'vue'
import { LocalStorage } from 'quasar'

export function BUSINESS_SET (state, business) {
  Vue.set(state, 'business', business)
  Vue.set(state, 'businessId', business.id)
  LocalStorage.set('business.businessId', business.id)
  Vue.set(state, 'loading', false)
}

export function BUSINESS_LOADING (state, payload) {
  Vue.set(state, 'loading', payload)
}

export function BUSINESS_SET_CLOCK (state, timeObject) {
  Vue.set(state, 'clock', timeObject)
}

export function BUSINESS_SET_MENUS (state, menus) {
  Vue.set(state, 'menus', menus)
}

// TODO: set allDay to all items in hours[] in store
// Allday is for every day in part
export function BUSINESS_SET_HOURS (state, { business, hours }) {
  let allDay = hours[0].get('allDay')

  // Check if it open all day in this weekday
  if (!allDay) {
    const now = new Date()
    const day = now.getDay() + 1
    if(hours[day]) {
      allDay = hours[day].get('allDay')
    }
  }

  const result = []

  // No need for time modifier, we get absolut(local for this business) time from server
  // const timezoneModifier = parseInt(business.get('time_zone').split(')')[0].split('UTC')[1].split(':')[0]) + (new Date().getTimezoneOffset() / 60)

  if (hours.length) {
    for (const i in hours) {
      if (!result[hours[i].get('weekday')]) {
        result[hours[i].get('weekday')] = []
      }

      for (let j = 0; j < hours[i].get('hours').length; j += 4) {
        result[hours[i].get('weekday')].push({
          from: {
            hours: parseInt(hours[i].get('hours')[j + 0]),
            minutes: parseInt(hours[i].get('hours')[j + 1])
          },
          to: {
            hours: parseInt(hours[i].get('hours')[j + 2]),
            minutes: parseInt(hours[i].get('hours')[j + 3])
          },
          allDay: hours[i].get('allDay'),
          closed: hours[i].get('closed'),
        })
      }
    }
    Vue.set(state, 'hours', result)
    Vue.set(state, 'isOpenAllDay', allDay)
  }
}

export function BUSINESS_STORE_BUSINESSES (state, businesses) {
  Vue.set(state, 'all', businesses)
}

export function BUSINESS_CLEAR_LOCALSTORAGE (state) {
  const localStorage = LocalStorage.getAll()
  for (const index in localStorage) {
    if (index.indexOf('auth.') === -1) {
      LocalStorage.remove(index)
    }
  }
}

export function BUSINESS_UNSET (state) {
  Vue.set(state, 'business', false)
  Vue.set(state, 'businessId', false)
  LocalStorage.set('business.businessId',false)
}

export function BUSINESS_SET_CURRENCY (state, currency) {
  Vue.set(state, 'currency', currency)
}
