import Vue from 'vue'
import { LocalStorage } from 'quasar'

export function CART_READY (state) {
  Vue.set(state, 'ready', true)
}

export function ITEMS_CART_SET_ITEM_EXTRA (state, { extra, status }) {
  const extras = state.currentItem.get('extras')

  let currentIndex = false
  for (const i in extras) {
    if ((extras[i].id && extras[i].id === extra.id) || extras[i].objectId === extra.id) {
      if (!status) {
        extras.splice(i, 1)
      } else {
        currentIndex = i
      }
      break
    }
  }

  if (status && currentIndex === false) {
    extras.push({
      __type: 'Pointer',
      className: 'RestaurantItemExtra',
      objectId: extra.id
    })
  }
  state.currentItem.set('extras', extras)
  Vue.set(state, 'enabledExtras', extras)
}

export function ITEMS_CART_TOGGLE_ITEM_EXTRA (state, extra) {
  const extras = state.currentItem.get('extras')
  let found = false
  const extraId = (extra.id) ? extra.id : extra.objectId

  for (const i in extras) {
    if ((extras[i].id && extras[i].id === extraId) || extras[i].objectId === extraId) {
      extras.splice(i, 1)
      found = true
    }
  }

  if (!found) {
    extras.push({
      __type: 'Pointer',
      className: 'RestaurantItemExtra',
      objectId: extra.id
    })
  }

  state.currentItem.set('extras', extras)
  Vue.set(state, 'enabledExtras', extras)
}

export function CART_RESET (state) {
  Vue.set(state, 'price', 0)
  Vue.set(state, 'itemsCountById', {})
  Vue.set(state, 'items', [])
  LocalStorage.remove('cart.items')
}

export async function CART_DELETE_ITEM (state, itemIndex) {
  if (state.items[itemIndex].id) {
    await state.items[itemIndex].destroy()
  }

  state.itemsCountById[state.items[itemIndex].get('restaurant_item').id]--
  state.items.splice(itemIndex, 1)
  LocalStorage.set('cart.items', state.items)
}

export function CART_SET_CURRENT_ITEM (state, item) {
  Vue.set(state, 'currentItem', item)
  Vue.set(state, 'enabledExtras', item.get('extras'))
}

export function CART_LOADING (state, status = true) {
  Vue.set(state, 'loading', status)
}

export function CART_SET_ITEMS (state, { items, order }) {
  Vue.set(state, 'items', items)

  LocalStorage.set('cart.items', items)
  for (const i in items) {
    if (!items[i].get('restaurant_item_summary') && order && order.id) {
      items[i].set('restaurant_item_summary', order)
    }

    if (items[i].get('restaurant_item') && items[i].get('restaurant_item').id) {
      if (!state.itemsCountById[items[i].get('restaurant_item').id]) {
        Vue.set(state.itemsCountById, items[i].get('restaurant_item').id, 0)
      }
      state.itemsCountById[items[i].get('restaurant_item').id]++
    }
  }
}

export function CART_ADD_ITEMS (state, items) {
  for (const i in items) {
    state.items.push(items[i])
    if (items[i].get('restaurant_item') && !state.itemsCountById[items[i].get('restaurant_item').id]) {
      Vue.set(state.itemsCountById, items[i].get('restaurant_item').id, 0)
    }
    state.itemsCountById[items[i].get('restaurant_item').id]++
  }
  LocalStorage.set('cart.items', state.items)
}

export function CART_UPDATE_CURRENT_ITEM (state, itemIndex) {
  Vue.set(state.items, itemIndex, state.currentItem)
  LocalStorage.set('cart.items', state.items)
}

export function CART_RESET_EXTRAS (state, extras) {
  Vue.set(state, 'enabledExtras', [])
}

export function CART_RESET_CURRENT_ITEM (state) {
  Vue.set(state, 'currentItem', false)
}
