export const order = (state) => (orderId) => {
  return state.orders[orderId]
}

export function loading (state) {
  return state.loading
}

export function iframeUrl (state) {
  return state.iframeUrl
}

export function steps (state) {
  return state.steps
}

export function justCompleted (state) {
  return state.justCompleted
}
