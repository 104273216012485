import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import layout from './layout'

import addresses from './addresses'
import business from './business'
import categories from './categories'
import items from './items'
import orders from './orders'
import paymentMethods from './payment_methods'
import payments from './payments'
import tables from './tables'
import ratings from './ratings'

import cart from './cart'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      auth,
      layout,

      addresses,
      business,
      categories,
      items,
      orders,
      paymentMethods,
      payments,
      tables,
      ratings,

      cart
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEBUGGING
  })

  return Store
}
