import { Parse } from 'src/boot/parse'
import languages from 'quasar/lang/index.json'

export function logged (state) {
  return (state.token.length && Parse.User.current() !== null)
}

export const getLangIndex = (state) => (iso) => {
  return state.languages.findIndex(lang => lang.iso === iso)
}

export const getFlagByLang = (state) => (lang) => state.flags[lang]

export const langOptions = (state) => {
  return languages.filter(lang => {
    const splited = lang.isoName.split('-')
    const name = splited[0]
    const country = splited[1]

    return state.languages.includes(name) && (country ? (state.flags[name] === country) : true)
  })
}

export function user (state) {
  return state.user
}

export function loading (state) {
  return state.loading
}

export function saving (state) {
  return state.saving
}

export function token (state) {
  return state.token
}

export function verificationCode (state) {
  return state.verificationCode
}

export function location (state) {
  return state.location
}

export function checked (state) {
  return state.checked
}

export function addresses (state) {
  return state.addresses
}
