import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)
export const Router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,

  // Leave these as they are and change in quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  mode: process.env.VUE_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE
})

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

function old ({ store }) {
  Router.beforeEach((to, from, next) => {
    if (to.path.includes('external-orders') || to.name === 'business.direct') {
      return next()
    }
    // paths that redirect to /
    if (to.path !== '/') {
      if (from.path.includes('external-orders')) {
        return next()
      }
      if (!store.getters['business/businessId'] && to.name !== 'business.direct') {
        // If no business is selected - go ONLY to the landing page / direct business link
        next('/')
      } else if (store.getters['business/isOpen']) {
        if (!to.meta.allowEmptyOrder && !store.getters['orders/mode']) {
          // If the business is open and no order mode is selected
          next('/')
        } else if (!to.meta.allowNoOrder && !store.getters['orders/orderId']) {
          // If the business is open and the user has no order
          next('/')
        }
      }
    }

    // if (to.meta.redirectActiveOrder && !!store.getters['orders/orderId'] && !store.getters['orders/finished']() && to.name.indexOf('order.tracker') === -1) {
    //   // If the user has an active order
      // next({ name: 'order.tracker.order' })
    // } else if (store.getters['payments/justCompleted']) {
    //   // Don't redirect immediately after payment (to show lottie success)
    //   next(false)
    // } else 
    if (to.meta.auth) {
      if (store.getters['auth/checked']) {
        if (store.getters['auth/logged']) {
          next()
        } else {
          next('/')
          store.dispatch('layout/setLoginPopup', true)
        }
      } else {
        store.subscribe(({ type }) => {
          if (type === 'auth/AUTH_CHECKED') {
            store.dispatch('layout/setLoginPopup', !store.getters['auth/logged'])
            next()
          }
        })
        store.dispatch('auth/refresh')
        console.log('refreshed')
      }
    } else {
      // Design features
      store.dispatch('layout/setSideMenuShadow', !!to.meta.sideMenuShadow)
      store.dispatch('layout/setRouterPopup', !!to.meta.routerPopup)

      if (!to.meta.retainOrder && store.getters['orders/finished']) {
        store.dispatch('orders/checkOrderFinished')
      }

      next()
    }
  })

  return Router
}

export default function ({ store }) {
  Router.beforeEach((to, from, next) => { 
    // Design features
    store.dispatch('layout/setSideMenuShadow', !!to.meta.sideMenuShadow)
    store.dispatch('layout/setRouterPopup', !!to.meta.routerPopup)
    return next()
  })

  return Router
}
