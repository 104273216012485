import Vue from 'vue'
import { getObjectByKeys } from 'src/utils/index'

export function PAYMENTS_ORDERS_SET (state, { orderId, orderPayments }) {
  Vue.set(state.orders, orderId, orderPayments)
}

export function PAYMENTS_IFRAME_URL_SET (state, iframeUrl) {
  Vue.set(state, 'iframeUrl', iframeUrl)
}

export function PAYMENTS_LOADING (state, status) {
  Vue.set(state, 'loading', status)
}

export function PAYMENTS_CHANGE_STEP (state, targetStep) {
  for (const i in state.steps) {
    if (state.steps[i]) {
      Vue.set(state.steps, i, false)
    }
  }
  Vue.set(state.steps, targetStep, true)
}

export function PAYMENTS_MARK_COMPLETE (state, status) {
  state.justCompleted = status
}

const orderKeys = ['client', 'temp_total_price', 'take_away', 'paid', 'item_orders', 'item_orders_delivered', 'paid_by_kitchen', 'paid_by_waiter', 'refund', 'paid_by_admin', 'closed_by_admin', 'business', 'additional_amount', 'discount_amount', 'discount_percentage', 'tip']
const paymentKeys = ['payme_json', 'price', 'client', 'currency', 'payment_method', 'tip', 'payme_transaction_id', 'auto_invrec_or_receipt', 'payment_method_type', 'stripe_payment', 'stripe_json', 'stripe_payment_intent']
const jsonKeys = ['payme_transaction_total', 'buyer_name', 'buyer_card_mask', 'sale_paid_date']

export function ADD_RECEIPT (state, { order, payment }) {
  // console.log('add', payment, order)
  if (!payment && order) {
    Vue.set(state.receipts, order.id, {
      id: order.id,
      order,
      ...getObjectByKeys(orderKeys, order)
    })

    return
  } else if (!payment && !order) return

  const currentOrder = order ?? payment.get('restaurant_order_summary')
  
  // Stripe Payment
  const isStripePayment = payment.get('stripe_payment')


  // console.log('order', getObjectByKeys(orderKeys, order))
  // console.log('payment', getObjectByKeys(paymentKeys, payment))
  const json = payment ? isStripePayment ? JSON.parse(payment.get('stripe_json')) : JSON.parse(payment.get('payme_json')) : {}

  Vue.set(state.receipts, currentOrder.id, {
    id: currentOrder.id,
    order,
    ...getObjectByKeys(orderKeys, currentOrder),
    ...getObjectByKeys(paymentKeys, payment),
    ...(isStripePayment ? {} :getObjectByKeys(jsonKeys, json))
  })
}

export function REMOVE_RECEIPT (state, id) {
  Vue.delete(state.receipts, id)
}
