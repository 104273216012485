import { Parse } from 'src/boot/parse'
import { Notify, Platform } from 'quasar'
import { i18n } from 'src/boot/i18n'
import languages from 'quasar/lang/index.json'

export function resetVerificationCode ({ commit }) {
  commit('AUTH_VERIFICATION_CODE_SET', false)
}

export function sendVerificationCode ({ commit, rootState }, { countryCode, phoneNumber, country }) {
  commit('AUTH_LOADING', true)
  return Parse.Cloud
    .run('sendVerificationCode', {
      from: (rootState.business.business && rootState.business.business.attributes && Object.keys(rootState.business.business).length)
        ? rootState.business.business.get('title_en') : 'DreamDiner',
      country: country,
      phoneNumber: countryCode + phoneNumber.replace(/^0+/, ''),
    }).then((code) => {
      commit('AUTH_VERIFICATION_CODE_SET', code)
    }).catch(error => {
      console.log('#### Auth error: ', error)
    }).finally(() => {
      commit('AUTH_LOADING', false)
    })
}

export async function verifyAdminPin ({ commit }, pin) {
  commit('AUTH_LOADING', true)
  await new Parse.Query('Business')
    .equalTo('pin', pin)
    .find()
    .then(res => {
      if (res.length) {
        commit('AUTH_VERIFIED')
      } else {
        Notify.create({
          type: 'negative',
          message: 'Incorrect code'
        })
      }
    })
  commit('AUTH_LOADING', false)
}

export function checkUserExists ({ commit }, username) {
  return new Parse.Query('User')
    .equalTo('username', username.replace(/^0+/, ''))
    .find()
}

// Test func for push n
// const userId = "7YZUofNDjK"
// export function runPushTest ({ state }) {
//   console.log('run test', userId)
//   Parse.Cloud.run('sendMePush', { userId: userId })
// }

export async function initPushNotifications ({ commit, state }) {
  const { FCM } = require('@capacitor-community/fcm')
  const { PushNotifications } = require('@capacitor/push-notifications')

  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    Notify.create({
      type: 'negative',
      message: i18n.t('auth.notification_alert')
    })
  }

  // Register with Apple / Google to receive push via APNS/FCM
  if (Platform.is.ios) {
    PushNotifications.addListener('registration', () => {
      FCM.getToken().then(result => Parse.User.current().set('fcm_token', result.token).save())
    })
  } else if (Platform.is.android) {
    PushNotifications.addListener('registration', result => {
      Parse.User.current().set('fcm_token', result.value).save()
    })
  }
  await PushNotifications.addListener('pushNotificationReceived', notification => { });

  await PushNotifications.addListener('pushNotificationActionPerformed', notification => { });
  PushNotifications.register()
}

export function getLocation ({ commit, state }, location = false) {
  return new Promise((resolve, reject) => {
    if (location !== false) {
      location = new Parse.GeoPoint(location)
      commit('AUTH_SET_LOCATION', location)
      resolve(location)
    } else if (state.location !== false) {
      resolve(state.location)
    } else {
      if (Platform.is.capacitor) {
        const { Geolocation } = require('@capacitor/geolocation')
        Geolocation.getCurrentPosition().then(({ coords }) => {
          location = new Parse.GeoPoint(coords)
          commit('AUTH_SET_LOCATION', location)
          resolve(location)
        }).catch((e) => {
          console.warn('getLocation error:', e)
          resolve(false)
        })
      } else {
        navigator.geolocation.getCurrentPosition(({ coords }) => {
          location = new Parse.GeoPoint(coords)
          commit('AUTH_SET_LOCATION', location)
          resolve(location)
        }, (e) => {
          console.warn('getLocation error:', e)
          resolve(false)
        }, { enableHighAccuracy: true })
      }
    }
  })
}

export async function logIn ({ commit, dispatch },
  { username, name, userExists, countryCode = false, phone = null, country = null }) {
  commit('AUTH_LOADING', true)

  if (!phone) {
    phone = username.replace(countryCode, '')
  }

  let platform = 'cl_web'
  if (Platform.is.capacitor) {
    if (Platform.is.ios) {
      platform += '_ios'
    } else if (Platform.is.android) {
      platform += '_and'
    }
  }

  if (!userExists) {
    const user = new Parse.User({
      username: username.replace(/^0+/, ''),
      password: username.replace(/^0+/, ''),
      phone: phone,
      name: name,
      registered_using: platform,
      blocked: false,
      is_admin: false,
      country: country
    })
    try {
      await user.signUp()
    } catch (e) {
      Notify.create({
        type: 'negative',
        message: 'Error: ' + e.code + ' ' + e.message
      })
    }
  }

  await Parse.User.logIn(username.replace(/^0+/, ''), username.replace(/^0+/, ''), { usePost: true }).then(user => {
    // console.log('user', user)
    commit('AUTH_TOKEN_SET', user.get('sessionToken'))
    dispatch('afterLogin')
  })

  commit('AUTH_LOADING', false)
}

export function afterLogin ({ dispatch }) {
  dispatch('updateLoggedUser')
  // dispatch('getLocation')
  if (Platform.is.capacitor) {
    dispatch('initPushNotifications')
  }
}

export async function logout ({ commit, dispatch }) {
  console.log('logout')
  await Parse.User.logOut()
  await dispatch('cart/removeItems', null, { root: true })
  await dispatch('orders/destroy', false, { root: true })

  commit('AUTH_LOGOUT')
}

export async function getAddresses ({ commit }) {
  commit('AUTH_LOADING', true)
  await new Parse.Query('Address')
    .equalTo('client', Parse.User.current())
    .find()
    .then(res => {
      commit('AUTH_STORE_ADDRESSES', res)
    })
  commit('AUTH_LOADING', false)
}

export async function updateLoggedUser ({ commit, dispatch }, userData = null) {
  commit('AUTH_LOADING', true)
  const user = Parse.User.current()
  if (userData) {
    await user.set(userData).save()
  }

  if (user) {
    const onboarding = user.get('onboarding')
    if (onboarding) {
      for(const key in onboarding) {
        commit('AUTH_SET_ONBOARD_TIP', { key, value: onboarding[key] })
      }
    }
  }
  commit('AUTH_STORE_USER', user)
  commit('AUTH_LOADING', false)
}

export function refresh ({ state, commit, dispatch }) {
  if (state.token && state.token.length) {
    Parse.User
      .become(state.token)
      .then(() => dispatch('afterLogin'))
      .catch(() => dispatch('logout'))
  }

  commit('AUTH_CHECKED')
}

export async function staffLogin ({ state, commit, dispatch }, username) {
  let user = null

  await Parse.User.logIn(username.replace(/^0+/, ''),
    username.replace(/^0+/, ''), { usePost: true })
    .then(result => {
      user = result
      commit('AUTH_TOKEN_SET', user.get('sessionToken'))
    })

  return user
}

export async function findUserByStaff ({ state, commit, dispatch }, ID) {
  // if (Parse.User.current() && Parse.User.current().id !== ID) {
  //   console.log('findUserByStaff current user is different (logout)', Parse.User.current().id)
  //   await dispatch('logout')
  // }
  return await Parse.Cloud
    .run('findUserByStaff', {
      staffId: ID
    }).then((result) => {
      return result
    }).catch(async (error) => {
      if (Parse.User.current()) {
        return await dispatch('logout')
      } else {
        return error
      }
    })
}

const defaultBoard = {    
  category: true,
  tray: true,
  order: true,
  tracker: true,
  payment: true
}
/**
 * @param {Record<key, value>} onboarding 
 */
export async function saveOnboardingTip({ state, commit, getters }, onboarding ) {
  const logged = getters.logged
  const current = (logged && state.user.get('onboarding')) ? state.user.get('onboarding') : defaultBoard
  
  if (current) {
    for(const key in onboarding) {
      if (!onboarding[key]) {
        commit('AUTH_SET_ONBOARD_TIP', { key: key, value: onboarding[key] })
        current[key] = onboarding[key]
      }
    }

    if (logged) {
      await state.user.set('onboarding', current)
      await state.user.save()
    }
  }
}

export async function resetOnboardingTip({ state, commit, getters }) {

  for(const key in defaultBoard) {
    commit('AUTH_SET_ONBOARD_TIP', { key: key, value: true })
  }

  if (getters.logged) {
    await state.user.set('onboarding', defaultBoard)
    await state.user.save()
  }
}

export function findAppFullLocale({ state, commit, getters }, locale) {
  const splited = locale.split('-')
  const shortLocale = splited[0]
  const country = getters.getFlagByLang(locale)
  const full = `${locale}-${country}`

  let result = false
  for(const lang of languages) {
    if(lang.isoName === shortLocale || lang.isoName === full) {
      if(state.languages.includes(shortLocale)) {
        result = lang
        break
      }
    }
  }

  return result
}
