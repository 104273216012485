import timezones from 'src/utils/timezones.json'

const formatPriceWithCurrency = (price, currencyPrefix, currencySuffix) => {
  if (currencyPrefix) {
    return `${currencyPrefix} ${price}`;
  } else {
    return `${price} ${currencySuffix}`;
  }
};

function getObjectByKeys(keys, object) {
  const result = {}

  for (const key of keys) {
    if (key === 'payme_json' && !!object.get('payme_json')) {
      result[key] = JSON.parse(object.get(key))
      continue
    }
    result[key] = object.get ? object.get(key) : object[key]
  }
  return result
}

function getTimezoneByText( text) {
  for (let i = 0; i < timezones.length; i++) {
      if (timezones[i].text === text) {
          return timezones[i];
      }
  }
  return null;
}

function getTimeOfTimezone(timezoneText) {
  const timezone = getTimezoneByText(timezoneText);
  if (timezone) {
      const now = new Date().toLocaleString("en-US", {timeZone: timezone.utc[0]});
      const [timeString, ampm] = now.split(", ")[1].split(" ");
      const [hours, minutes] = timeString.split(":").map(Number);
      const adjustedHours = (hours % 12) + (ampm === "PM" ? 12 : 0);
      return { hours: adjustedHours, minutes };
  } else {
      console.error('Timezone not found');
      return null;
  }
}
export { 
  getTimeOfTimezone,
  getTimezoneByText, 
  formatPriceWithCurrency, 
  getObjectByKeys };
