export default function () {
  return {
    all: false,
    loading: false,
    current: false,
    countedItems: {
      specials: 0
    }
  }
}
