import Vue from 'vue'

export function CATEGORIES_STORE (state, categories) {
  Vue.set(state, 'all', categories)
}

export function CATEGORIES_LOADING (state, status) {
  Vue.set(state, 'loading', status)
}

export function CATEGORIES_SET (state, category) {
  Vue.set(state, 'current', category)
}

export function SET_COUNTED (state, { categoryId, counts }) {
  Vue.set(state.countedItems, categoryId, counts)
}
