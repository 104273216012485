import Vue from 'vue'
import { LocalStorage } from 'quasar'

export function LAYOUT_SET_SIDE_MENU_SHADOW (state, status) {
  Vue.set(state, 'sideMenuShadow', status)
}

export function LAYOUT_SET_ROUTER_POPUP (state, status) {
  Vue.set(state, 'routerPopup', status)
}

export function LAYOUT_SET_TABLES_POPUP (state, status) {
  Vue.set(state, 'tablesPopup', status)
}

export function LAYOUT_SET_PAYMENTS_POPUP (state, status) {
  Vue.set(state, 'paymentsPopup', status)
}

export function LAYOUT_SET_LOGIN_POPUP (state, status) {
  Vue.set(state, 'loginPopup', status)
}

export function LAYOUT_SET_BUSINESS_POPUP (state, status) {
  Vue.set(state, 'businessPopup', status)
}

export function LAYOUT_SET_FONT_ZOOM (state, payload) {
  state.fontZoom = payload
  LocalStorage.set('layout.zoom', payload)
}
