import { Parse } from 'src/boot/parse'

export function getOne ({ commit, rootState }, tableId) {
  commit('TABLES_LOADING', true)
  return new Parse.Query('Table')
    .get(tableId)
    .then(table => {
      commit('TABLES_SET_SELECTED', table)
    })
    .catch(error => {
      if (rootState.orders.current && rootState.orders.current.attributes && rootState.orders.current.get('table').id === tableId) {
        commit('TABLES_SET_ORDERS_TABLE', false)
        rootState.orders.current.unset('table')
      }
      console.log('#### Table get error:', error)
    }).then(() => {
      commit('TABLES_LOADING', false)
    })
}

export async function getAll ({ commit, rootState }) {
  commit('TABLES_LOADING', true)
  return await new Parse.Query('Table')
    .equalTo('business', rootState.business.business)
    .find()
    .then(tables => {
      commit('TABLES_SET', tables)
    })
    .catch(error => {
      console.log('#### Tables get error:', error)
    }).then(() => {
      commit('TABLES_LOADING', false)
    })
}

export async function setSelected ({ commit, rootState }, table) {
  commit('TABLES_SET_SELECTED', table)
}

export async function set ({ commit, rootState }, table) {
  commit('TABLES_SET_ORDERS_TABLE', table)
}

export async function getTableOrders ({ commit, dispatch, rootState }, table) {
  commit('TABLES_LOADING', true)

  if (!table || !table.className || !table.className.toLowerCase() !== 'table') {
    table = rootState.tables.table
    if (!table || !table.className || !table.className.toLowerCase() !== 'table') {
      table = rootState.orders.current.attributes.table
    }
  }

  const tableId = (table.id) ? table.id : table.objectId

  if (tableId) {
    await new Parse.Query('RestaurantOrderSummary')
      .equalTo('table', table)
      .notEqualTo('paid', true)
      .notEqualTo('closed_by_admin', true)
      .find()
      .then(async tableOrders => {
        const promises = []
        for (const i in tableOrders) {
          if (!tableOrders[i].get('client').get('name')) {
            promises.push(tableOrders[i].get('client').fetch())
          }

          const itemOrders = tableOrders[i].get('item_orders')
          for (const j in itemOrders) {
            if (!itemOrders[j] || (!itemOrders[j].id && !itemOrders[j].objectId)) {
              itemOrders.splice(j, 1)
            }
          }

          await dispatch('items/getOrderItemDetails', itemOrders, { root: true })
        }

        Promise.all(promises).then(() => {
          commit('TABLES_ORDERS_SET', {
            tableId: tableId,
            tableOrders: tableOrders
          })
        })
      }).catch(e => {
        console.log('#### Get Table Orders error:', e)
      }).then(() => {
        commit('TABLES_LOADING', false)
      })
  }
}
