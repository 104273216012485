export default [
  {
    path: "categories/specials",
    name: "categories.specials",
    components: {
      default: () => import("src/pages/categories/Show.vue"),
      toolbar: () => import("src/components/categories/Toolbar.vue"),
    },
    meta: {
      allowNoOrder: true,
      sideMenuShadow: true,
    },
  },
  {
    path: "categories",
    name: "categories.browse",
    component: () => import("src/pages/categories/Browse.vue"),
    meta: {
      allowNoOrder: true,
      sideMenuShadow: true,
    },
  },
  {
    path: "categories/:categoryId",
    name: "categories.show",
    meta: {
      allowNoOrder: true,
    },
    components: {
      default: () => import("src/pages/categories/Show.vue"),
      toolbar: () => import("src/components/categories/Toolbar.vue"),
    },
  },
  {
    path: "categories/specials/:itemId/",
    name: "items.add_from_specials",
    components: {
      default: () => import("src/pages/categories/Show.vue"),
      toolbar: () => import("src/components/categories/Toolbar.vue"),
      popup: () => import("src/pages/items/Add.vue"),
    },
    meta: {
      allowNoOrder: true,
      routerPopup: true,
    },
  },
  {
    path: "categories/:categoryId/:itemId/",
    name: "items.add",
    props: {
      default: false,
      toolbar: false,
      popup: true,
    },
    components: {
      default: () => import("src/pages/categories/Show.vue"),
      toolbar: () => import("src/components/categories/Toolbar.vue"),
      popup: () => import("src/pages/items/Add.vue"),
    },
    meta: {
      allowNoOrder: true,
      routerPopup: true,
    },
  },
  {
    path: "categories/specials/:itemId/:cartItemIndex",
    name: "cart.edit_item_from_specials",
    components: {
      default: () => import("src/pages/categories/Show.vue"),
      toolbar: () => import("src/components/categories/Toolbar.vue"),
      popup: () => import("src/pages/cart/EditItem.vue"),
    },
    meta: {
      allowNoOrder: true,
      routerPopup: true,
    },
  },
  {
    path: "categories/:categoryId/:itemId/:cartItemIndex",
    name: "cart.edit_item",
    components: {
      default: () => import("src/pages/categories/Show.vue"),
      toolbar: () => import("src/components/categories/Toolbar.vue"),
      popup: () => import("src/pages/cart/EditItem.vue"),
    },
    meta: {
      allowNoOrder: true,
      routerPopup: true,
    },
  },
];
