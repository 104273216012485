import Vue from 'vue'

export function PAYMENT_METHODS_DELETING (state, status) {
  Vue.set(state, 'deleting', status)
}

export function PAYMENT_METHODS_DELETE (state, index) {
  state.all.splice(index, 1)
}

export function PAYMENT_METHODS_STORE (state, paymentMethods) {
  Vue.set(state, 'all', paymentMethods)
}

export function PAYMENT_METHODS_STORE_ONE (state, paymentMethod) {
  let found = false
  for (const i in state.all) {
    if (state.all[i].id === paymentMethod.id) {
      found = true
      Vue.set(state.all, i, paymentMethod)
    }
  }

  if (!found) {
    state.all.push(paymentMethod)
  }
}

export function PAYMENT_METHODS_LOADING (state, status) {
  Vue.set(state, 'loading', status)
}

export function PAYMENT_METHODS_SAVING (state, status) {
  Vue.set(state, 'saving', status)
}
