import { LocalStorage } from 'quasar'

export default function () {
  return {
    all: [],
    orderId: LocalStorage.has('order.id') ? LocalStorage.getItem('order.id') : false,
    current: false,
    tableOrdersLoading: false,
    mode: LocalStorage.has('order.mode') ? LocalStorage.getItem('order.mode') : false,
    subscription: false,
    socketUpdateFlag: false,
    loading: false,
    waiterCall: false,
    waiterCallId: LocalStorage.has('order.call_id') ? LocalStorage.getItem('order.call_id') : false,
    hasWaiterCall: false,
    hasCashCall: false,
    data: LocalStorage.has('order.data') ? LocalStorage.getItem('order.data') : {},
    role: LocalStorage.has('order.role') ? LocalStorage.getItem('order.role') : 'guest',
    extra_client_name: LocalStorage.has('order.client_name') ? LocalStorage.getItem('order.client_name') : null,
    showClientNamePopup: false,
    refundeds: {},
    showActives: LocalStorage.has('order.show_actives') ? LocalStorage.getItem('order.show_actives'): true
  }
}
