export default [
  {
    path: 'user/edit',
    name: 'user.edit',
    component: () => import('src/pages/user/Edit.vue'),
    meta: {
      pageBg: 'grey7',
      allowEmptyOrder: true,
      allowNoOrder: true,
      auth: true
    }
  },
  {
    path: 'user/orders',
    name: 'user.orders',
    component: () => import('src/pages/user/Orders.vue'),
    meta: {
      pageBg: 'grey7',
      allowEmptyOrder: true,
      allowNoOrder: true,
      auth: true
    }
  },
  {
    path: 'user/order/:id',
    name: 'user.order',
    component: () => import('src/pages/user/Order.vue'),
    meta: {
      pageBg: 'grey7',
      allowEmptyOrder: true,
      allowNoOrder: true,
      auth: true
    }
  }
]
