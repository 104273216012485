export default function () {
  return {
    loading: false,
    orders: {},
    iframeUrl: '',
    justCompleted: false,
    steps: {
      amount: true,
      tip: false,
      method: false,
      summary: false
    },
    receipts: {}
  }
}
