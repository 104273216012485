export function sideMenuShadow (state) {
  return state.sideMenuShadow
}

export function routerPopup (state) {
  return state.routerPopup
}

export function tablesPopup (state) {
  return state.tablesPopup
}

export function paymentsPopup (state) {
  return state.paymentsPopup
}

export function loginPopup (state) {
  return state.loginPopup
}

export function businessPopup (state) {
  return state.businessPopup
}
