import { Parse } from 'src/boot/parse'

export function select ({ commit }, address) {
  commit('ADDRESSES_SELECT', address)
}

export function get ({ commit }) {
  commit('ADDRESSES_LOADING', true)
  return new Parse.Query('Address')
    .equalTo('client', Parse.User.current())
    .ascending('title')
    .find()
    .then(addresses => {
      commit('ADDRESSES_STORE', addresses)
      commit('ADDRESSES_LOADING', false)
    })
    .catch(error => {
      console.log('#### Addresses get error:', error)
    })
}

export async function add ({ commit, state }, data) {
  commit('ADDRESSES_SAVING', true)

  const address = new Parse.Object('Address', data)
  return address.save()
    .then(() => {
      commit('ADDRESSES_STORE', state.all.concat([address]))
      commit('ADDRESSES_SAVING', false)
    })
    .catch(error => {
      console.log('#### Address save error:', error)
    })
}

export async function update ({ commit, state }, address) {
  commit('ADDRESSES_SAVING', true)
  return address.save()
    .then(() => {
      commit('ADDRESSES_STORE_ONE', address)
      commit('ADDRESSES_SAVING', false)
    })
    .catch(error => {
      console.log('#### Address save error:', error)
    })
}

export async function destroy ({ commit, state }, id) {
  commit('ADDRESSES_DELETING', true)

  for (const i in state.all) {
    if (state.all[i].id === id) {
      const address = state.all[i]

      commit('ADDRESSES_DELETE', i)

      address.destroy()
        .then(() => {
          commit('ADDRESSES_DELETING', false)
        })
        .catch(error => {
          console.log('#### Address delete error:', error)
        })
    }
  }
}
