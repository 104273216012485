import { Parse } from 'src/boot/parse'

export function get ({ commit }) {
  commit('PAYMENT_METHODS_LOADING', true)
  return new Parse.Query('PaymentMethod')
    .equalTo('client', Parse.User.current())
    .ascending('card_title')
    .find()
    .then(paymentMethods => {
      commit('PAYMENT_METHODS_STORE', paymentMethods)
      commit('PAYMENT_METHODS_LOADING', false)
    })
    .catch(error => {
      console.log('#### PaymentMethods get error:', error)
    })
}

export async function add ({ commit, state }, data) {
  commit('PAYMENT_METHODS_SAVING', true)

  const paymentMethod = new Parse.Object('PaymentMethod', data)
  return paymentMethod.save()
    .then(() => {
      commit('PAYMENT_METHODS_STORE', state.all.concat([paymentMethod]))
      commit('PAYMENT_METHODS_SAVING', false)
    })
    .catch(error => {
      console.log('#### PaymentMethod save error:', error)
    })
}

export async function update ({ commit, state }, paymentMethod) {
  commit('PAYMENT_METHODS_SAVING', true)
  return paymentMethod.save()
    .then(() => {
      commit('PAYMENT_METHODS_STORE_ONE', paymentMethod)
      commit('PAYMENT_METHODS_SAVING', false)
    })
    .catch(error => {
      console.log('#### PaymentMethod save error:', error)
    })
}

export async function destroy ({ commit, state }, id) {
  commit('PAYMENT_METHODS_DELETING', true)

  for (const i in state.all) {
    if (state.all[i].id === id) {
      const paymentMethod = state.all[i]

      commit('PAYMENT_METHODS_DELETE', i)

      paymentMethod.destroy()
        .then(() => {
          commit('PAYMENT_METHODS_DELETING', false)
        })
        .catch(error => {
          console.log('#### PaymentMethod delete error:', error)
        })
    }
  }
}
